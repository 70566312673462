import React, { Component } from 'react';
import classnames from 'classnames';

import Orbs from 'components/Util/Misc/Orbs';

import styles from 'styles/Marketing/Legal/Base';

export default class Terms extends Component {
  render() {
    const orbs = [
      { 
        width: '600px',
        height: '600px',
        top: '-570px',
        right: 'calc(50% - 775px)',
        background: styles.colorPrimaryPurple,
        animationDuration: '8s',
        animationDelay: '-1s',
      },
      // LIGHT BLUE ORB
      {
        width: '75px',
        height: '75px',
        bottom: '-20px',
        right: 'calc(50% - 670px)',
        background: '#18cefe',
        animationDuration: '8s',
        animationDelay: '-1s',
      },
      // ORANGE ORB
      // top right
      {
        width: '200px',
        height: '200px',
        top: '-120px',
        left: 'calc(50% - 750px)',
        background: styles.colorSecondaryOrange,
        animationDuration: '8s',
      },
    ];

    return (
      <React.Fragment>
        <Orbs orbs={orbs} blurEffect={false}>
          <div className={classnames(styles.MaxWidth, 'mx-auto', 'px-lg-4', 'px-2', 'py-5', 'text-center', 'position-relative')}>
            <h1 className={classnames(styles.h1, 'mb-3', styles.ColorPrimaryWhite)}>Terms of Service</h1>
            <p className={classnames(styles.p1, styles.UpperContent, styles.Tint4, 'pt-3', 'mx-auto')} style={{ maxWidth: '600px' }}>Last updated: July 1, 2022</p>
          </div>
        </Orbs>

        <div className={classnames('mx-auto', 'px-lg-4', 'px-2', 'text-start', 'py-4')} style={{ maxWidth: '800px' }}>
          <p className={classnames(styles.p1)}>These Amply Terms of Service (this "Agreement") are entered into by Send Amply Inc. ("Amply") and the entity executing this Agreement ("You"). This Agreement governs Your use of Amply (the "Service"). BY CLICKING THE "I ACCEPT" BUTTON, COMPLETING THE REGISTRATION PROCESS, OR USING THE SERVICE, YOU ACKNOWLEDGE THAT YOU HAVE REVIEWED AND ACCEPT THIS AGREEMENT AND ARE AUTHORIZED TO ACT ON BEHALF OF, AND BIND TO THIS AGREEMENT, THE OWNER OF THIS ACCOUNT. In consideration of the foregoing, the parties agree as follows:</p>

          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1')}>Definitions</h3>
          <p className={classnames(styles.p1)}>The “Agreement” refers, collectively, to all the terms, conditions, notices contained or referenced in this document (the “Terms of Service” or the "Terms") and all other operating rules, policies (including the Amply Privacy Policy, available at https://sendamply.com/privacy) and procedures that we may publish from time to time on the Website.</p>
          <p className={classnames(styles.p1)}>The “Service” refers to the applications, software, products, and services provided by Amply.</p>
          <p className={classnames(styles.p1)}>The “Website” refers to Amply’s website located at sendamply.com, and all content, services, and products provided by Amply at or through the Website. It also refers to Amply-owned subdomains of sendamply.com, such as smtp.sendamply.com. Occasionally, websites owned by Amply may provide different or additional terms of service. If those additional terms conflict with this Agreement, the more specific terms apply to the relevant page or service.</p>
          <p className={classnames(styles.p1)}> "Account" refers to the billing account for the Service.</p>
          <p className={classnames(styles.p1)}>“The User,” “You,” and “Your” refer to the individual person, company, or organization that has visited or is using the Website or Service; that accesses or uses any part of the Account; or that directs the use of the Account in the performance of its functions. A User must be at least 13 years of age.</p>
          <p className={classnames(styles.p1)}>A “User Account” represents an individual User’s authorization to log in to and use the Service and serves as a User’s identity on Amply.</p>
          <p className={classnames(styles.p1)}>“Amply,” “We,” and “Us” refers to Send Amply Inc., as well as our affiliates, directors, subsidiaries, contractors, licensors, officers, agents, and employees.</p>
          <p className={classnames(styles.p1)}>“Content” refers to content featured or displayed through the Website, including without limitation text, data, articles, images, photographs, graphics, software, applications, designs, features, and other materials that are available on the Website or otherwise available through the Service. "Content" also includes Services. “User-Generated Content” is Content, written or otherwise, created or uploaded by our Users. "Your Content" is Content that you create or own.</p>
          <p className={classnames(styles.p1)}>"Email" refers to any email that is processed by Amply servers and sent to your recipients on your behalf.</p>
          <p className={classnames(styles.p1)}>"Customer Data" or "Amply Analytics Data" means the data you collect, process or store using the Service concerning the characteristics and activities of the Email you send.</p>
          <p className={classnames(styles.p1)}> "Processing Software" means the Amply server-side software and any upgrades, which analyzes the Customer Data.</p>
          <p className={classnames(styles.p1)}> "Servers" means the servers controlled by Amply (or its wholly owned subsidiaries) on which the Processing Software and Customer Data are stored.</p>
          <p className={classnames(styles.p1)}>"Third Party" means any third party (i) to which You provide access to Your Account or (ii) for which You use the Service to collect information on the third party's behalf.</p>
          <p className={classnames(styles.p1)}> The words "include" and "including" mean "including but not limited to."</p>

          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1')}>Account</h3>
          <p className={classnames(styles.p1)}> To register for the Service, You must complete the registration process by providing Amply with current, complete and accurate information as prompted by the registration form, including Your e-mail address (username) and password. You will protect Your passwords and take full responsibility for Your own, and third party, use of Your accounts. You are solely responsible for any and all activities that occur under Your Account. You will notify Amply immediately upon learning of any unauthorized use of Your Account or any other breach of security. Amply's (or its wholly-owned subsidiaries') support staff may, from time to time, log in to the Service in order to maintain or improve service, including to provide You assistance with technical or billing issues.</p>

          <h5 className={classnames(styles.h5, styles.ColorPrimaryPurple, 'mt-3', 'mb-1')}>Requirements</h5>
          <p className={classnames(styles.p1)}>You must be a human to create an Account. Accounts registered by "bots" or other automated methods are not permitted.</p>
          <p className={classnames(styles.p1)}>One person or legal entity may maintain no more than one free Account.</p>
          <p className={classnames(styles.p1)}>You must be age 13 or older.</p>
          <p className={classnames(styles.p1)}>Your login may only be used by one person — i.e., a single login may not be shared by multiple people.</p>

          <h5 className={classnames(styles.h5, styles.ColorPrimaryPurple, 'mt-3', 'mb-1')}>User Account Security</h5>
          <p className={classnames(styles.p1)}>You are responsible for keeping your Account secure while you use our Service. You are responsible for all content posted and activity that occurs under your Account.</p>
          <p className={classnames(styles.p1)}>You are responsible for maintaining the security of your Account and password. Amply cannot and will not be liable for any loss or damage from your failure to comply with this security obligation.</p>
          <p className={classnames(styles.p1)}>You will promptly notify Amply if you become aware of any unauthorized use of, or access to, our Service through your Account, including any unauthorized use of your password or Account.</p>

          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1')}>Acceptable Use</h3>
          <h5 className={classnames(styles.h5, styles.ColorPrimaryPurple, 'mt-3', 'mb-1')}>Compliance with Laws and Regulations</h5>
          <p className={classnames(styles.p1)}>Your use of the Website and Service must not violate any applicable laws, including copyright or trademark laws, export control laws, or other laws in your jurisdiction. You are responsible for making sure that your use of the Service is in compliance with laws and any applicable regulations.</p>

          <h5 className={classnames(styles.h5, styles.ColorPrimaryPurple, 'mt-3', 'mb-1')}>Privacy</h5>
          <p className={classnames(styles.p1)}>Any person, entity, or service collecting data from Amply must comply with the Amply Privacy Policy, particularly in regards to the collection of Users' Personal Information (as defined in the Amply Privacy Policy). If you collect any Amply User's Personal Information from Amply, you agree that you will only use the Personal Information you gather for the purpose for which our User has authorized it. You will have and abide by an appropriate Privacy Policy. You agree that you will reasonably secure any Personal Information you have gathered from Amply, and you will respond promptly to complaints, removal requests, and "do not contact" requests from Amply or Amply Users.</p>

          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1')}>Information Rights</h3>
          <p className={classnames(styles.p1)}>Amply and its wholly owned subsidiaries may retain and use, subject to the terms of its <a href='/legal/privacy'>Privacy Policy</a>, information collected in Your use of the Service. Amply will not share Your Customer Data with any third parties unless Amply (i) has Your consent for any Customer Data; (ii) concludes that it is required by law or has a good faith belief that access, preservation or disclosure of Customer Data is reasonably necessary to protect the rights, property or safety of Amply, its Users or the public; or (iii) provides Customer Data in certain limited circumstances to third parties to carry out tasks on Amply's behalf (e.g., billing or data storage) with strict restrictions that prevent the data from being used or shared except as directed by Amply. When this is done, it is subject to agreements that oblige those parties to process Customer Data only on Amply's instructions and in compliance with this Agreement and appropriate confidentiality and security measures.</p>

          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1')}>Acceptable Use</h3>
          <p className={classnames(styles.p1)}>You are responsible for any fees associated with your use of Amply. We are responsible for communicating those fees to you clearly and accurately, and letting you know well in advance if those prices change.</p>

          <h5 className={classnames(styles.h5, styles.ColorPrimaryPurple, 'mt-3', 'mb-1')}>Pricing</h5>
          <p className={classnames(styles.p1)}>Our pricing and payment terms are available in the registration flow at <a href='/pricing'>https://sendamply.com/pricing</a> or your account page at <a href='/home/account'>https://sendamply.com/home/account</a>. If you agree to a subscription price, that will remain your price for the duration of the payment term; however, prices are subject to change at the end of a payment term.</p>

          <h5 className={classnames(styles.h5, styles.ColorPrimaryPurple, 'mt-3', 'mb-1')}>Upgrades, Downgrades, and Changes</h5>
          <p className={classnames(styles.p1)}>Changes to your plan may take up to three business days to take effect. If you change your plan in the middle of a payment term, your billing rate is determined by the most expensive plan that was in effect for that payment term.</p>
          <p className={classnames(styles.p1)}>If you choose to downgrade your Account, you may lose access to Content, features, or capacity of your Account.</p>

          <h5 className={classnames(styles.h5, styles.ColorPrimaryPurple, 'mt-3', 'mb-1')}>Billing Schedule; No Refunds</h5>
          <p className={classnames(styles.p1)}>The Service is billed at the end of each month and is non-refundable. Your first month's use of the Service is prorated and prepaid on registration.</p>
          <p className={classnames(styles.p1)}>There will be no refunds or credits for partial months of service, downgrade refunds, or refunds for months unused with an open Account; however, the Service will remain active for the length of the paid billing period.</p>
          <p className={classnames(styles.p1)}>In order to treat everyone equally, no exceptions will be made.</p>

          <h5 className={classnames(styles.h5, styles.ColorPrimaryPurple, 'mt-3', 'mb-1')}>Authorization</h5>
          <p className={classnames(styles.p1)}>By agreeing to these Terms, you are giving us permission to charge your on-file credit card or other approved methods of payment for fees that you authorize for Amply.</p>

          <h5 className={classnames(styles.h5, styles.ColorPrimaryPurple, 'mt-3', 'mb-1')}>Responsibility for Payment</h5>
          <p className={classnames(styles.p1)}>You are responsible for all fees, including taxes and overage charges, associated with your use of the Service. By using the Service, you agree to pay Amply any charge incurred in connection with your use of the Service. If you dispute the matter, contact Amply Support. You are responsible for providing us with a valid means of payment for Accounts.</p>

          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1')}>Cancellation and Termination</h3>
          <p className={classnames(styles.p1)}>You may cancel your Account at any time by submitting a cancellation request at <a href='/home/contact'>https://sendamply.com/home/contact</a>.</p>
          <p className={classnames(styles.p1)}>Upon any termination of this Agreement, Amply will stop providing, and You will stop accessing the Service; and You will delete all copies of Customer Data within 3 business days of such termination. In the event of any termination (a) You will not be entitled to any refunds of any usage fees or any other fees, and (b) any outstanding balance for Service rendered through the date of termination will be immediately due and payable in full and (c) all of Your historical Customer Data will no longer be available to You.</p>

          <h5 className={classnames(styles.h5, styles.ColorPrimaryPurple, 'mt-3', 'mb-1')}>Amply May Terminate</h5>
          <p className={classnames(styles.p1)}>Amply has the right to suspend or terminate your access to all or any part of the Website at any time, with or without cause, with or without notice, effective immediately. Amply reserves the right to refuse service to anyone for any reason at any time.</p>

          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1')}>Communication with Amply</h3>
          <p className={classnames(styles.p1)}>For contractual purposes, you (1) consent to receive communications from us in an electronic form via the email address you have submitted or via the Service; and (2) agree that all Terms of Service, agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that those communications would satisfy if they were on paper. This section does not affect your non-waivable rights.</p>

          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1')}>Disclaimer of Warranties</h3>
          <p className={classnames(styles.p1)}>Amply provides the Website and the Service “as is” and “as available,” without warranty of any kind. Without limiting this, we expressly disclaim all warranties, whether express, implied or statutory, regarding the Website and the Service including without limitation any warranty of merchantability, fitness for a particular purpose, title, security, accuracy and non-infringement.</p>
          <p className={classnames(styles.p1)}>Amply does not warrant that the Service will meet your requirements; that the Service will be uninterrupted, timely, secure, or error-free; that the information provided through the Service is accurate, reliable or correct; that any defects or errors will be corrected; that the Service will be available at any particular time or location; or that the Service is free of viruses or other harmful components. You assume full responsibility and risk of loss resulting from your downloading and/or use of files, information, content or other material obtained from the Service.</p>

          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1')}>Limitation of Liability</h3>
          <p className={classnames(styles.p1)}>You understand and agree that we will not be liable to you or any third party for any loss of profits, use, goodwill, or data, or for any incidental, indirect, special, consequential or exemplary damages. Our liability is limited whether or not we have been informed of the possibility of such damages, and even if a remedy set forth in this Agreement is found to have failed of its essential purpose. We will have no liability for any failure or delay due to matters beyond our reasonable control.</p>

          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1')}>Indemnification</h3>
          <p className={classnames(styles.p1)}>To the extent permitted by applicable law, You will indemnify, hold harmless and defend Amply and its wholly owned subsidiaries, at Your expense, from any and all third-party claims, actions, proceedings, and suits brought against Amply or any of its officers, directors, employees, agents or affiliates, and all related liabilities, damages, settlements, penalties, fines, costs or expenses (including, reasonable attorneys' fees and other litigation expenses) incurred by Amply or any of its officers, directors, employees, agents or affiliates, arising out of or relating to (i) Your breach of any term or condition of this Agreement, (ii) Your use of the Service, (iii) Your violations of applicable laws, rules or regulations in connection with the Service, (iv) any representations and warranties made by You concerning any aspect of the Service, the Processing Software or Customer Data to any Third Party; (v) any claims made by or on behalf of any Third Party pertaining directly or indirectly to Your use of the Service, the Software or Reports; (vi) violations of Your obligations of privacy to any Third Party; and (vii) any claims with respect to acts or omissions of any Third Party in connection with the Service, the Software or Reports. Amply will provide You with written notice of any claim, suit or action from which You must indemnify Amply. You will cooperate as fully as reasonably required in the defense of any claim. Amply reserves the right, at its own expense, to assume the exclusive defense and control of any matter subject to indemnification by You.</p>

          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1')}>Changes to these Terms</h3>
          <p className={classnames(styles.p1)}>Amply may modify these terms or any additional terms that apply to the Service to, for example, reflect changes to the law or changes to the Service. You should look at the terms regularly. We will notify our Users of material changes to this Agreement at least 14 days prior to the change taking effect by electronic communication via the email address on your Account. If You do not agree to the modified terms for the Service, You should discontinue Your use of the Service. No amendment to or modification of this Agreement will be binding unless (i) in writing and signed by a duly authorized representative of Amply, (ii) You accept updated terms online, or (iii) You continue to use the Service after Amply has posted updates to the Agreement or to any policy governing the Service.</p>
        </div>
      </React.Fragment>
    );
  }
}
