import React, { Component } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import Orbs from 'components/Util/Misc/Orbs';
import Divider from '../Divider';

import CCPA from './Images/Security/CCPA';
import GDPR from './Images/Security/GDPR';

import Minus from 'components/Util/Icons/Minus';
import Plus from 'components/Util/Icons/Plus';

import styles from 'styles/Marketing/Legal/Base';

@observer
export default class Security extends Component {
  table = observable.object({ selected: '' });

  onClick = (data) => {
    if (this.table.selected == data) {
      this.table.selected = '';
    }
    else {
      this.table.selected = data;
    }
  }

  render() {
    const orbs = [
      { 
        width: '600px',
        height: '600px',
        top: '-570px',
        right: 'calc(50% - 775px)',
        background: styles.colorPrimaryPurple,
        animationDuration: '8s',
        animationDelay: '-1s',
      },
      // LIGHT BLUE ORB
      {
        width: '75px',
        height: '75px',
        bottom: '-20px',
        right: 'calc(50% - 670px)',
        background: '#18cefe',
        animationDuration: '8s',
        animationDelay: '-1s',
      },
      // ORANGE ORB
      // top right
      {
        width: '200px',
        height: '200px',
        top: '-120px',
        left: 'calc(50% - 750px)',
        background: styles.colorSecondaryOrange,
        animationDuration: '8s',
      },
    ];

    const orbs2 = [
      // LIGHT BLUE ORGB
      { 
        width: '200px',
        height: '200px',
        bottom: '-50px',
        right: 'calc(50% - 650px)',
        background: '#18cefe'
      },
      // PURPLE ORBS
      // bottom left
      { 
        width: '400px',
        height: '400px',
        top: '0',
        left: 'calc(50% - 550px)',
        background: styles.colorPrimaryPurple,
      },
      {
        width: '400px',
        height: '400px',
        top: '-200px',
        left: 'calc(50% - 650px)',
        background: styles.colorPrimaryPurple,
      },
      {
        width: '200px',
        height: '200px',
        bottom: '-50px',
        right: 'calc(50% - 500px)',
        background: styles.colorPrimaryPurple,
      },
    ];

    const table = this.table;

    return (
      <div className='position-relative overflow-hidden'>
        <Orbs orbs={orbs} blurEffect={false}>
          <div className={classnames(styles.MaxWidth, 'mx-auto', 'px-lg-4', 'px-2', 'py-5', 'text-center', 'position-relative')}>
            <h1 className={classnames(styles.h1, 'mb-3', styles.ColorPrimaryWhite)}>Security is in our DNA</h1>
            <p className={classnames(styles.p1, styles.UpperContent, styles.ColorPrimaryWhite, 'pt-3', 'mx-auto')} style={{ maxWidth: '460px' }}>Amply is designed with the most security-conscious Security and IT teams in mind. Our policy consists of five critical components — corporate governance, data security, security architecture, product security, and data privacy.</p>
            <GDPR color={styles.colorPrimaryWhite} className='mx-2' />
            <CCPA color={styles.colorPrimaryWhite} className='mx-2' />
          </div>
        </Orbs>

        <div className={classnames(styles.MaxWidth, 'mx-auto', 'px-lg-4', 'px-2', 'text-center', 'py-5', 'pb-0')} style={{ zIndex: '13' }}>
          <h2 className={classnames(styles.h2, styles.ColorPrimaryPurple, 'mb-3')}>Enterprise security that goes <br/>above and beyond compliance</h2>

          <ul className={classnames(styles.SecurityList, 'text-start')}>
            <li onClick={() => this.onClick('corporate_governance')} className={classnames(table.selected === 'corporate_governance' ? styles.Active : '')}>
              <div className='row'>
                <div className='col-10'>
                  <h5 className={classnames(styles.h5)}>Corporate Governance</h5>
                </div>
                <div className='col-2 text-end'>
                  <Plus color={styles.colorPrimaryWhite} className={classnames(styles.Plus)} />
                  <Minus color={styles.colorPrimaryWhite} className={classnames(styles.Minus)} />
                </div>
              </div>

              <p>Security starts with policy that guides how we run business, product, and operations. Some of these policies include employee confidentiality agreements, mandatory background checks for all new employees, multi-factor authentication on every internal and external service, security awareness training, and a well-documented Incident Response Plan.</p>
            </li>

            <li onClick={() => this.onClick('data_security')} className={classnames(table.selected === 'data_security' ? styles.Active : '')}>
              <div className='row'>
                <div className='col-10'>
                  <h5 className={classnames(styles.h5)}>Data Security</h5>
                </div>
                <div className='col-2 text-end'>
                  <Plus color={styles.colorPrimaryWhite} className={classnames(styles.Plus)} />
                  <Minus color={styles.colorPrimaryWhite} className={classnames(styles.Minus)} />
                </div>
              </div>

              <p>Physical access to customer data is strictly controlled. All systems that store sensitive customer data are encrypted at rest (AES-256 or stronger), employees are only granted access to data when it is absolutely required for their role, and only customer-designated operators can access deployments for the purpose of administration and support.</p>
            </li>

            <li onClick={() => this.onClick('security_architecture')} className={classnames(table.selected === 'security_architecture' ? styles.Active : '')}>
              <div className='row'>
                <div className='col-10'>
                  <h5 className={classnames(styles.h5)}>Security Architecture</h5>
                </div>
                <div className='col-2 text-end'>
                  <Plus color={styles.colorPrimaryWhite} className={classnames(styles.Plus)} />
                  <Minus color={styles.colorPrimaryWhite} className={classnames(styles.Minus)} />
                </div>
              </div>

              <p>Amply's security architecture is based on the principle of least privilege — an entity may only have access to data, resources, and applications needed to complete a required task. Deployments are separated from outside connections through our DMZ, and all traffic is closely monitored and logged. Furthermore, all Amply clients use TLS/SSL when communicating for data and API calls and retention periods can be configured to meet our customer's requirements.</p>
            </li>

            <li onClick={() => this.onClick('product_security')} className={classnames(table.selected === 'product_security' ? styles.Active : '')}>
              <div className='row'>
                <div className='col-10'>
                  <h5 className={classnames(styles.h5)}>Product Security</h5>
                </div>
                <div className='col-2 text-end'>
                  <Plus color={styles.colorPrimaryWhite} className={classnames(styles.Plus)} />
                  <Minus color={styles.colorPrimaryWhite} className={classnames(styles.Minus)} />
                </div>
              </div>

              <p>Amply is built with industry-standard technology and security best practices. SSO is supported via SAML or Oauth2 (G Suite and Office 365) and OWASP guidelines are strictly adhered to. We peer review code commits, run static analysis on our release branch, and regularly perform vulnerability scans on our network.</p>
            </li>

            <li onClick={() => this.onClick('data_privacy')} className={classnames(table.selected === 'data_privacy' ? styles.Active : '')}>
              <div className='row'>
                <div className='col-10'>
                  <h5 className={classnames(styles.h5)}>Data Privacy</h5>
                </div>
                <div className='col-2 text-end'>
                  <Plus color={styles.colorPrimaryWhite} className={classnames(styles.Plus)} />
                  <Minus color={styles.colorPrimaryWhite} className={classnames(styles.Minus)} />
                </div>
              </div>

              <p>Amply's infrastructure is designed to keep each customer's data in isolated environments, and retention periods can be configured to meet customer needs. See Amply's <a href='/legal/privacy'>Privacy Policy</a> for more information on what Customer Data is stored.</p>
            </li>
          </ul>

          <div className={classnames(styles.Callout)}>
            <Orbs orbs={orbs2} className={classnames('mx-auto', 'py-3', 'mt-5')} blurEffect={false} style={{ zIndex: '13' }}>
              <div className='text-center py-4'>
                <h2 className={classnames(styles.h2, styles.ColorPrimaryWhite)}>Bug Bounty</h2>
                <p className={classnames(styles.p1, styles.ColorPrimaryWhite, 'mb-0')}>Please contact <a href='mailto:security@sendamply.com' className={classnames(styles.ColorSecondaryOrange)}>security@sendamply.com</a> if you would like to learn about our bounty program. Do not attempt to exploit or find vulnerabilities in our production environment, including any domains at *.sendamply.com.</p>
              </div>
            </Orbs>
          </div>

        </div>

        <div className={classnames(styles.Spacer)} />
        <Divider className={classnames(styles.Divider)} />
      </div>
    );
  }
}
