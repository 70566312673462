import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'mobx-react';

// Marketing
import Root from 'containers/marketing/root/Show';
// import Platform from 'containers/marketing/platform/Show';
import Pricing from 'containers/marketing/pricing/Show';
import Contact from 'containers/marketing/contact/Show';

import NewSession from 'containers/marketing/organizations/sessions/New';
import NewPassword from 'containers/marketing/organizations/passwords/New';
import EditPassword from 'containers/marketing/organizations/passwords/Edit';
import NewConfirmation from 'containers/marketing/organizations/confirmations/New';

import LegalSecurity from 'containers/marketing/legal/Security';
import LegalDataProcessingAddendum from 'containers/marketing/legal/DataProcessingAddendum';
import LegalServiceLevelAgreement from 'containers/marketing/legal/ServiceLevelAgreement';
import LegalSubprocessors from 'containers/marketing/legal/Subprocessors';
import LegalTerms from 'containers/marketing/legal/Terms';
import LegalPrivacy from 'containers/marketing/legal/Privacy';

import Cohorts from 'containers/cohorts/Index';

// Dashboard
import DashboardRoot from 'containers/dashboard/root/Show';
import UsersIndex from 'containers/dashboard/analytics/users/Index';
import UsersShow from 'containers/dashboard/analytics/users/Show';

import SQLNew from 'containers/dashboard/analytics/sql/New';
import SQLShow from 'containers/dashboard/analytics/sql/Show';
import SearchNew from 'containers/dashboard/analytics/search/New';

import GeneralSettingsShow from 'containers/dashboard/settings/general/Show';

import IpAddressesIndex from 'containers/dashboard/settings/ip_addresses/Index';
import IpAddressesShow from 'containers/dashboard/settings/ip_addresses/Show';

import VerifiedDomainsIndex from 'containers/dashboard/settings/verified_domains/Index';
import VerifiedDomainsNew from 'containers/dashboard/settings/verified_domains/New';
import VerifiedDomainsShow from 'containers/dashboard/settings/verified_domains/Show';

import AccessTokensIndex from 'containers/dashboard/settings/access_tokens/Index';
import AccessTokensNew from 'containers/dashboard/settings/access_tokens/New';
import AccessTokensShow from 'containers/dashboard/settings/access_tokens/Show';

import IpPoolsIndex from 'containers/dashboard/settings/ip_pools/Index';
import IpPoolsNew from 'containers/dashboard/settings/ip_pools/New';
import IpPoolsShow from 'containers/dashboard/settings/ip_pools/Show';

import UnsubscribeGroupsIndex from 'containers/dashboard/settings/unsubscribe_groups/Index';
import UnsubscribeGroupsNew from 'containers/dashboard/settings/unsubscribe_groups/New';
import UnsubscribeGroupsShow from 'containers/dashboard/settings/unsubscribe_groups/Show';

import UnsubscribeGroupsUsersIndex from 'containers/dashboard/settings/unsubscribe_groups/users/Index';
import UnsubscribeGroupsUsersNew from 'containers/dashboard/settings/unsubscribe_groups/users/New';
import UnsubscribeGroupsUsersShow from 'containers/dashboard/settings/unsubscribe_groups/users/Show';

import UnsubscribeGroupsQuarantinedUsersIndex from 'containers/dashboard/settings/unsubscribe_groups/quarantined_users/Index';
import UnsubscribeGroupsQuarantinedUsersShow from 'containers/dashboard/settings/unsubscribe_groups/quarantined_users/Show';

import TemplatesIndex from 'containers/dashboard/settings/templates/Index';
import TemplatesNew from 'containers/dashboard/settings/templates/New';
import TemplatesShow from 'containers/dashboard/settings/templates/Show';

import WebhooksIndex from 'containers/dashboard/settings/webhooks/Index';
import WebhooksNew from 'containers/dashboard/settings/webhooks/New';
import WebhooksShow from 'containers/dashboard/settings/webhooks/Show';

import SSOConnectionsIndex from 'containers/dashboard/sso_connections/Index';
import SSOConnectionsNew from 'containers/dashboard/sso_connections/New';
import SSOConnectionsShow from 'containers/dashboard/sso_connections/Show';

import OrganizationAccountsIndex from 'containers/dashboard/users/Index';
import OrganizationAccountsSettings from 'containers/dashboard/users/settings/Show';
import OrganizationAccountsNew from 'containers/dashboard/users/New';
import OrganizationAccountsShow from 'containers/dashboard/users/Show';

import AccountShow from 'containers/dashboard/account/Show';
import AccountBilling from 'containers/dashboard/account/Billing';
import AccountPlan from 'containers/dashboard/account/Plan';

import MonitorsIndex from 'containers/dashboard/monitors/Index';
import MonitorsNew from 'containers/dashboard/monitors/New';
import MonitorsShow from 'containers/dashboard/monitors/Show';

import OrganizationContact from 'containers/dashboard/contact/Show';

import UnsubscribeShow from 'containers/users/unsubscribe/Show';

// Visualizations
import ShowWidget from 'containers/visualizations/widgets/Show';

// Errors
import Error from 'containers/errors/Show';

export default function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path='/' component={Root} />
          <Route exact path='/pricing' component={Pricing} />
          <Route exact path='/contact' component={Contact} />

          <Route exact path='/organizations/sign_in' component={NewSession} />
          <Route exact path='/organizations/password/new' component={NewPassword} />
          <Route exact path='/organizations/password/edit' component={EditPassword} />
          <Route exact path='/organizations/confirmation/new' component={NewConfirmation} />

          <Route exact path='/legal/security' component={LegalSecurity} />
          <Route exact path='/legal/data-processing-addendum' component={LegalDataProcessingAddendum} />
          <Route exact path='/legal/service-level-agreement' component={LegalServiceLevelAgreement} />
          <Route exact path='/legal/subprocessors' component={LegalSubprocessors} />
          <Route exact path='/legal/terms' component={LegalTerms} />
          <Route exact path='/legal/privacy' component={LegalPrivacy} />

          <Route exact path='/cohorts' component={Cohorts} />

          <Route exact path='/home' component={DashboardRoot} />

          <Route exact path='/home/analytics/users' component={UsersIndex} />
          <Route exact path='/home/analytics/users/:email' component={UsersShow} />

          <Route exact path='/home/analytics/searches/sql/new' component={SQLNew} />
          <Route exact path='/home/analytics/searches/sql/:id' component={SQLShow} />
          <Route exact path='/home/analytics/searches/basic/new' component={SearchNew} />

          <Route exact path='/home/settings/general' component={GeneralSettingsShow} />

          <Route exact path='/home/settings/ip_addresses' component={IpAddressesIndex} />
          <Route exact path='/home/settings/ip_addresses/:id' component={IpAddressesShow} />

          <Route exact path='/home/settings/verified_domains' component={VerifiedDomainsIndex} />
          <Route exact path='/home/settings/verified_domains/new' component={VerifiedDomainsNew} />
          <Route exact path='/home/settings/verified_domains/:id' component={VerifiedDomainsShow} />

          <Route exact path='/home/settings/access_tokens' component={AccessTokensIndex} />
          <Route exact path='/home/settings/access_tokens/new' component={AccessTokensNew} />
          <Route exact path='/home/settings/access_tokens/:id' component={AccessTokensShow} />

          <Route exact path='/home/settings/ip_pools' component={IpPoolsIndex} />
          <Route exact path='/home/settings/ip_pools/new' component={IpPoolsNew} />
          <Route exact path='/home/settings/ip_pools/:id' component={IpPoolsShow} />

          <Route exact path='/home/settings/unsubscribe_groups' component={UnsubscribeGroupsIndex} />
          <Route exact path='/home/settings/unsubscribe_groups/new' component={UnsubscribeGroupsNew} />
          <Route exact path='/home/settings/unsubscribe_groups/:id' component={UnsubscribeGroupsShow} />

          <Route exact path='/home/settings/unsubscribe_groups/:id/users' component={UnsubscribeGroupsUsersIndex} />
          <Route exact path='/home/settings/unsubscribe_groups/:id/users/new' component={UnsubscribeGroupsUsersNew} />
          <Route exact path='/home/settings/unsubscribe_groups/:id/users/:email' component={UnsubscribeGroupsUsersShow} />

          <Route exact path='/home/settings/unsubscribe_groups/:id/quarantined_users' component={UnsubscribeGroupsQuarantinedUsersIndex} />
          <Route exact path='/home/settings/unsubscribe_groups/:id/quarantined_users/:email' component={UnsubscribeGroupsQuarantinedUsersShow} />

          <Route exact path='/home/settings/templates' component={TemplatesIndex} />
          <Route exact path='/home/settings/templates/new' component={TemplatesNew} />
          <Route exact path='/home/settings/templates/:id' component={TemplatesShow} />

          <Route exact path='/home/settings/webhooks' component={WebhooksIndex} />
          <Route exact path='/home/settings/webhooks/new' component={WebhooksNew} />
          <Route exact path='/home/settings/webhooks/:type/:id' component={WebhooksShow} />

          <Route exact path='/home/sso_connections' component={SSOConnectionsIndex} />
          <Route exact path='/home/sso_connections/new' component={SSOConnectionsNew} />
          <Route exact path='/home/sso_connections/:id' component={SSOConnectionsShow} />

          <Route exact path='/home/users' component={OrganizationAccountsIndex} />
          <Route exact path='/home/users/settings' component={OrganizationAccountsSettings} />
          <Route exact path='/home/users/new' component={OrganizationAccountsNew} />
          <Route exact path='/home/users/:id' component={OrganizationAccountsShow} />

          <Route exact path='/home/contact' component={OrganizationContact} />

          <Route exact path='/home/account' component={AccountShow} />
          <Route exact path='/home/account/billing' component={AccountBilling} />
          <Route exact path='/home/account/plan' component={AccountPlan} />

          <Route exact path='/home/monitors' component={MonitorsIndex} />
          <Route exact path='/home/monitors/new' component={MonitorsNew} />
          <Route exact path='/home/monitors/:id' component={MonitorsShow} />

          <Route path='/v/widgets' component={ShowWidget} />

          <Route path="/u/unsubscribe/:jwe" component={UnsubscribeShow} />

          <Route component={Error} />
        </Switch>
      </div>
    </Router>
  );
}
