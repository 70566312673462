import React, { Component } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import RouteEmail from './Images/Section2/RouteEmail';
import MonitorInfrastructure from './Images/Section2/MonitorInfrastructure';
import ProductInsights from './Images/Section2/ProductInsights';
import SecurityStatus from './Images/Section2/SecurityStatus';

import styles from 'styles/Marketing/Root/Section2';

@observer
export default class Section2 extends Component {
  list = observable.object({ active: 0 });

  onClick = (value) => {
    this.list.active = value;
  }

  render() {
    return (
      <div className={classnames(styles.Container, 'pt-5', 'pb-3')}>
        <div className={classnames(styles.MaxWidth, 'mx-auto', 'p-4', 'pb-0')}>
          <h2 className={classnames(styles.h2, 'text-center', 'mb-0', styles.ColorPrimaryPurple)}>
            Turn your data <br className='d-none d-lg-block' /> into powerful insights <br className='d-none d-lg-block' /> with Amply's email API
          </h2>

          <div className={classnames('position-relative', 'px-2', 'px-lg-5', 'py-4')}>
            <div className={classnames(styles.Features, 'row', 'mb-2')}>
              <div
                onClick={() => this.onClick(0)}
                className={classnames('col-lg-4', this.list.active === 0 && styles.Active)}>

                <div>
                  <h6>Delivery</h6>
                  Intelligent Email Routing
                </div>
              </div>

              <div
                onClick={() => this.onClick(1)}
                className={classnames('col-lg-4', this.list.active === 1 && styles.Active)}>

                <div>
                  <h6>Visibility</h6>
                  Sending Health Monitors
                </div>
              </div>

              <div
                onClick={() => this.onClick(2)}
                className={classnames('col-lg-4', this.list.active === 2 && styles.Active)}>

                <div>
                  <h6>Analytics</h6>
                  Actionable User Analytics
                </div>
              </div>
            </div>

            <div className={classnames(styles.Screenshot)}>
              <RouteEmail className={classnames(this.list.active !== 0 && 'd-none', 'w-100', 'h-100')} />
              <MonitorInfrastructure className={classnames(this.list.active !== 1 && 'd-none', 'w-100', 'h-100')} />
              <ProductInsights className={classnames(this.list.active !== 2 && 'd-none', 'w-100', 'h-100')} />
              <SecurityStatus className={classnames(this.list.active !== 3 && 'd-none', 'w-100', 'h-100')} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
