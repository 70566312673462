import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Button from 'components/Util/Buttons/LightBackground/MediumPrimarySquareButton';
import LoadableCard from 'components/Dashboard/LoadableCard';

import api from 'models/API';

import styles from 'styles/Dashboard/Base';

@observer
export default class DnsConfiguration extends Component {
  componentDidMount = () => {
    const { verifiedDomain } = this.props;
    const verifyDns = this.verifyDns;

    const loadingInterval = setInterval(() => {
      if (verifiedDomain.loaded) {
        clearInterval(loadingInterval);

        if (!verifiedDomain.verified_at) {
          verifyDns();
        }
      }
    }, 500);
  }

  verifying = () => {
    return (
      <div className={classnames(styles.ColorPrimaryPurple, 'spinner-border spinner-border-md')} style={{width: '24px', height: '24px'}} role='status'>
        <span className='visually-hidden'>Loading...</span>
      </div>
    );
  }

  present = () => {
    return (
      <span className={classnames(styles.ColorSuccess)}>Present</span>
    )
  }

  missing = () => {
    return (
      <span className={classnames(styles.ColorError)}>Missing</span>
    )
  }

  displayStatus = (errors, record) => {
    const { verifiedDomain } = this.props;

    if (verifiedDomain.verified_at) {
      return this.present();
    }

    if (verifiedDomain.is_verifying) {
      return this.verifying();
    }

    switch (record) {
      case 'spf':
        if (this.matchesErrorMessage(errors, 'return path')) {
          return this.missing();
        }
        break;
      case 'dkim':
        if (this.matchesErrorMessage(errors, 'domain key')) {
          return this.missing();
        }
        break;
      case 'whitelabel':
        if (this.matchesErrorMessage(errors, 'whitelabel')) {
          return this.missing();
        }
        break;
      case 'shared':
        if (this.matchesErrorMessage(errors, 'shared')) {
          return this.missing();
        }
        break;
    }

    return this.present();
  }

  matchesErrorMessage = (errors, msg) => {
    for (const error of errors) {
      if (error.message.match(msg) || error.message.match('not a valid format')) {
        return true;
      }
    }
    return false;
  }

  verifyDns = (e) => {
    const { verifiedDomain } = this.props;

    verifiedDomain.is_verifying = true;
    verifiedDomain.errors.clear();

    api.put(`/api/v1/settings/verified_domains/${verifiedDomain.id}/validate`).then(data => {
      verifiedDomain.is_verifying = false;
      verifiedDomain.verified_at = data.verified_at;

      const errors = data.errors || [];

      for (const error of errors) {
        verifiedDomain.errors.push(error);
      }

      // reload page on newly verified domain
      if (errors.length === 0) {
        window.location.reload();
      }
    });
  };

  render() {
    const { verifiedDomain } = this.props;

    return (
        <LoadableCard className='mb-2' loadState={verifiedDomain.loaded}>
          <h5 className={classnames(styles.p2, 'fw-bold')}>DNS Configuration</h5>

          { !verifiedDomain.verified_at && (
            <Button
              onClick={this.verifyDns}
              className='position-absolute'
              style={{top: '12px', right: '24px'}}>

              Verify Domain
            </Button>
          )}

          <table className={classnames(styles.BasicTable, 'mb-2')}>
            <thead>
              <tr>
                <th>Status</th>
                <th>Purpose</th>
                <th>Type</th>
                <th>Host</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{this.displayStatus(verifiedDomain.errors, 'spf')}</td>
                <td>Configures your return path domain for bounce processing and validates SPF for emails sent from your Amply dedicated IPs.</td>
                <td>CNAME</td>
                <td>{verifiedDomain.spfHost}</td>
                <td>{verifiedDomain.dns.spf}</td>
              </tr>
              <tr>
                <td>{this.displayStatus(verifiedDomain.errors, 'dkim')}</td>
                <td>Configures your DKIM domain and signs your emails for secure communication.</td>
                <td>CNAME</td>
                <td>{verifiedDomain.dkimHost}</td>
                <td>{verifiedDomain.dns.dkim}</td>
              </tr>
              <tr>
                <td>{this.displayStatus(verifiedDomain.errors, 'whitelabel')}</td>
                <td>Configures your whitelabel domain used for open tracking, clicktracking, and unsubscribes.</td>
                <td>CNAME</td>
                <td>{verifiedDomain.whitelabelHost}</td>
                <td>{verifiedDomain.dns.whitelabel}</td>
              </tr>
              <tr>
                <td>{this.displayStatus(verifiedDomain.errors, 'shared')}</td>
                <td>Authenticates Amply to send from our shared Amazon SES pool to maximize deliverability for low volume senders and high volume senders with sudden volume spikes.</td>
                <td>TXT</td>
                <td>{verifiedDomain.sharedHost}</td>
                <td>{verifiedDomain.dns.shared}</td>
              </tr>
            </tbody>
          </table>

          <p className={classnames(styles.p2, styles.Tint4, 'mt-2', 'mb-0')} style={{fontSize: '11px'}}>
            *Although not required, we strongly recommend <a className={classnames(styles.Tint4, 'fw-bold')} target='_blank' href='https://docs.sendamply.com/docs/dmarc'>creating a DMARC record</a> to improve deliverability and help protect your users from social engineering attacks.
          </p>

        </LoadableCard>
    );
  }
}
