import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

export default class ProductInsights extends Component {
  render() {
    const src = `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1400" height="780" viewBox="0 0 1400 780">
  <defs>
    <linearGradient id="linear-gradient" x1="0.5" y1="1" x2="0.5" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#fcfcfc"/>
      <stop offset="1" stop-color="#f4f9ff"/>
    </linearGradient>
    <clipPath id="clip-path">
      <rect width="220" height="760" fill="none"/>
    </clipPath>
    <clipPath id="clip-path-2">
      <rect id="Rectangle_2198" data-name="Rectangle 2198" width="93.26" height="30.598" fill="#fff"/>
    </clipPath>
    <filter id="Rectangle_2213" x="236" y="138" width="370" height="230" filterUnits="userSpaceOnUse">
      <feOffset input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="5" result="blur"/>
      <feFlood flood-color="#e1ebfe"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="Rectangle_2272" x="236" y="358" width="370" height="230" filterUnits="userSpaceOnUse">
      <feOffset input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="5" result="blur-2"/>
      <feFlood flood-color="#e1ebfe"/>
      <feComposite operator="in" in2="blur-2"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <linearGradient id="linear-gradient-2" x1="0.5" y1="0.99" x2="0.5" y2="-0.185" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#2a22f0"/>
      <stop offset="1" stop-color="#7c1ffc"/>
    </linearGradient>
    <linearGradient id="linear-gradient-4" y1="0.993" y2="0.082" xlink:href="#linear-gradient-2"/>
    <linearGradient id="linear-gradient-8" y1="0.989" y2="-0.333" xlink:href="#linear-gradient-2"/>
    <linearGradient id="linear-gradient-16" y1="0.973" y2="-2.382" xlink:href="#linear-gradient-2"/>
    <clipPath id="clip-path-3">
      <rect id="Rectangle_2240" data-name="Rectangle 2240" width="134" height="134" transform="translate(0 0)" fill="none"/>
    </clipPath>
    <linearGradient id="linear-gradient-19" x1="-1.015" y1="2.059" x2="-1.003" y2="2.059" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#ff4058"/>
      <stop offset="1" stop-color="#ff2428"/>
    </linearGradient>
    <radialGradient id="radial-gradient" cx="0.5" cy="0.5" r="0.546" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#25de42"/>
      <stop offset="0.735" stop-color="#2afa8d"/>
      <stop offset="1" stop-color="#29f954"/>
    </radialGradient>
    <linearGradient id="linear-gradient-21" x1="0.921" y1="0.084" x2="0.328" y2="0.853" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#0b151e"/>
      <stop offset="1" stop-color="#303a57"/>
    </linearGradient>
    <clipPath id="clip-Homepage_03_Fuel_Product_Insights">
      <rect width="1400" height="780"/>
    </clipPath>
  </defs>
  <g id="Homepage_03_Fuel_Product_Insights" data-name="Homepage_03 Fuel Product Insights" clip-path="url(#clip-Homepage_03_Fuel_Product_Insights)">
    <rect width="1400" height="780" fill="#fff"/>
    <rect id="Rectangle_2248" data-name="Rectangle 2248" width="1380" height="746" rx="15" transform="translate(10 24)" fill="url(#linear-gradient)"/>
    <rect id="Rectangle_667" data-name="Rectangle 667" width="1373" height="86" transform="translate(1390 136) rotate(180)" fill="#192038"/>
    <g id="Medium_Secondary_Icon_:default" data-name="Medium Secondary Icon :default" transform="translate(276 73)">
      <g id="Group_202" data-name="Group 202">
        <g id="Rectangle_488" data-name="Rectangle 488" fill="none" stroke="#394676" stroke-width="1">
          <rect width="93" height="36" rx="18" stroke="none"/>
          <rect x="0.5" y="0.5" width="92" height="35" rx="17.5" fill="none"/>
        </g>
      </g>
      <text id="DevOps" transform="translate(46.5 22.611)" fill="#394676" font-size="14" font-family="Inter"><tspan x="-26.141" y="0">Delivery</tspan></text>
    </g>
    <g id="Group_5074" data-name="Group 5074" transform="translate(-17 13)">
      <g id="Group_202-2" data-name="Group 202" transform="translate(396 60)">
        <g id="Rectangle_488-2" data-name="Rectangle 488" fill="none" stroke="#fff" stroke-width="1">
          <rect width="93" height="36" rx="18" stroke="none"/>
          <rect x="0.5" y="0.5" width="92" height="35" rx="17.5" fill="none"/>
        </g>
      </g>
      <text id="Product" transform="translate(443 82.611)" fill="#fff" font-size="14" font-family="Inter"><tspan x="-17.972" y="0">Users</tspan></text>
    </g>
    <g id="Group_5076" data-name="Group 5076" transform="translate(20 13)">
      <g id="Group_4877" data-name="Group 4877" transform="translate(4778.848 8152)">
        <g id="Group_4876" data-name="Group 4876" transform="translate(-3557 -8092)">
          <g id="Rectangle_1090" data-name="Rectangle 1090" transform="translate(0.151)" fill="none" stroke="#3c444f" stroke-width="1">
            <rect width="128" height="36" rx="8" stroke="none"/>
            <rect x="0.5" y="0.5" width="127" height="35" rx="7.5" fill="none"/>
          </g>
          <text id="This_Month" data-name="This Month" transform="translate(41.151 23)" fill="none" font-size="12" font-family="Inter"><tspan x="0" y="0">This Month</tspan></text>
        </g>
        <path id="calendar_today_FILL0_wght400_GRAD0_opsz24" d="M121.3,189a1.3,1.3,0,0,1-1.3-1.3v-9.1a1.3,1.3,0,0,1,1.3-1.3h.65V176h1.3v1.3h5.2V176h1.3v1.3h.65a1.3,1.3,0,0,1,1.3,1.3v9.1a1.3,1.3,0,0,1-1.3,1.3Zm0-1.3h9.1v-6.5h-9.1Zm0-7.8h9.1v-1.3h-9.1Zm0,0v0Z" transform="translate(-3663 -8256.5)" fill="#e1ebff"/>
      </g>
      <text id="This_Month-2" data-name="This Month" transform="translate(1260 83.5)" fill="#fff" font-size="14" font-family="Inter"><tspan x="0" y="0">This Month</tspan></text>
    </g>
    <g id="Repeat_Grid_3" data-name="Repeat Grid 3" transform="translate(10 10)" clip-path="url(#clip-path)">
      <g transform="translate(-10 -10)">
        <rect id="Rectangle_2247" data-name="Rectangle 2247" width="220" height="760" rx="15" transform="translate(10 10)" fill="#0b151e"/>
      </g>
    </g>
    <path id="Rectangle_2251" data-name="Rectangle 2251" d="M15,0H1365a15,15,0,0,1,15,15V40a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V15A15,15,0,0,1,15,0Z" transform="translate(10 10)" fill="#192038"/>
    <g id="Amply_Logo_White" data-name="Amply Logo White" transform="translate(71 78.402)" clip-path="url(#clip-path-2)">
      <path id="Path_15661" data-name="Path 15661" d="M79.5,19.4a6.112,6.112,0,0,0-6.2,2.622,6.119,6.119,0,0,0-9.539-.8L63.747,19.7H59.461V37.074h4.65V25.909a2.762,2.762,0,1,1,5.523-.064V37.074h4.651V25.909a2.761,2.761,0,1,1,5.522-.064V37.074h4.651V25.412A6.079,6.079,0,0,0,79.5,19.4" transform="translate(-40.089 -13.087)" fill="#fff"/>
      <rect id="Rectangle_2197" data-name="Rectangle 2197" width="4.651" height="23.987" transform="translate(68.783)" fill="#fff"/>
      <path id="Path_15662" data-name="Path 15662" d="M155.633,19.3a8.842,8.842,0,0,0-5.51,1.924V19.7h-4.286V43.684h4.651V35.815a8.831,8.831,0,0,0,5.146,1.65,9.082,9.082,0,0,0,0-18.162m-.419,14.352a5.021,5.021,0,0,1-4.727-5.271,4.755,4.755,0,1,1,9.454,0,5.021,5.021,0,0,1-4.727,5.271" transform="translate(-98.324 -13.087)" fill="#fff"/>
      <path id="Path_15663" data-name="Path 15663" d="M8.784,19.306a14.285,14.285,0,0,0-7.537,2.13l1.438,3.747a12.193,12.193,0,0,1,4.924-1.792c2.522-.3,3.97.648,3.97,1.645-2.6.864-10.278,2-11.369,5.871-1.1,3.906,2.373,6.168,4.824,6.168h6.018l-2.67-1.524,2.174-5.745a.036.036,0,0,0-.062-.035L6.547,34.5h0L3.191,32.589l8.547-4.879v9.365h4.49V26.322c0-5.967-3.776-7.016-7.445-7.016" transform="translate(0 -13.088)" fill="#fff"/>
      <path id="Path_15664" data-name="Path 15664" d="M243.844,20.525l-4.085,11.637-4.085-11.637H230.71l6.567,17.364-2.505,6.623h4.964l9.072-23.987Z" transform="translate(-155.547 -13.914)" fill="#fff"/>
    </g>
    <g id="Group_5015" data-name="Group 5015" transform="translate(0 97.729)">
      <g id="Group_5018" data-name="Group 5018" transform="translate(10 220)">
        <g id="Group_4854" data-name="Group 4854" transform="translate(0 0)">
          <text id="Mail_Settings" data-name="Mail Settings" transform="translate(65 33.271)" fill="#394676" font-size="18" font-family="FKDisplay-Regular, FK Display, FKDisplayAlt" letter-spacing="-0.015em"><tspan x="0" y="0">Mail Settings</tspan></text>
          <g id="Group_4676" data-name="Group 4676" transform="translate(0 0.271)">
            <rect id="Rectangle_1827" data-name="Rectangle 1827" width="218" height="55" transform="translate(0 0)" fill="none"/>
          </g>
          <g id="Group_5017" data-name="Group 5017" transform="translate(22 12.271)">
            <g id="Medium_Icon_Button_:default" data-name="Medium Icon Button :default" transform="translate(0 0)">
              <rect id="base" width="32" height="32" rx="16" fill="#192038"/>
            </g>
            <path id="settings_FILL0_wght400_GRAD0_opsz48" d="M86.916,193.963l-.449-2.829a6.015,6.015,0,0,1-.9-.427,6.172,6.172,0,0,1-.831-.561l-2.65,1.212L80,187.676l2.425-1.774a2.669,2.669,0,0,1-.056-.46q-.011-.258-.011-.46t.011-.46a2.669,2.669,0,0,1,.056-.46L80,182.287l2.088-3.682,2.65,1.213a6.174,6.174,0,0,1,.831-.561,4.646,4.646,0,0,1,.9-.4L86.916,176h4.132l.449,2.829a7.279,7.279,0,0,1,.909.415,3.866,3.866,0,0,1,.82.573l2.65-1.213,2.088,3.682-2.425,1.729a3.164,3.164,0,0,1,.056.483q.011.258.011.483t-.011.471a3.126,3.126,0,0,1-.056.472l2.425,1.751-2.088,3.682-2.65-1.212a7.453,7.453,0,0,1-.82.573,3.913,3.913,0,0,1-.909.415l-.449,2.829Zm2.066-6.063a2.913,2.913,0,1,0-2.066-.853A2.814,2.814,0,0,0,88.982,187.9Zm0-1.347a1.572,1.572,0,1,1,1.111-.46A1.514,1.514,0,0,1,88.982,186.553ZM88.982,184.982Zm-.988,7.634h1.976l.314-2.515a5.3,5.3,0,0,0,1.4-.561,5.755,5.755,0,0,0,1.2-.921l2.38,1.033.9-1.617-2.111-1.549q.09-.382.146-.752a5.014,5.014,0,0,0,.056-.752,6.246,6.246,0,0,0-.045-.752,4.648,4.648,0,0,0-.157-.752l2.111-1.549-.9-1.617-2.38,1.033a5.005,5.005,0,0,0-1.168-.977,3.787,3.787,0,0,0-1.437-.505l-.314-2.515H87.994l-.314,2.515a4.758,4.758,0,0,0-1.426.539,4.953,4.953,0,0,0-1.179.943l-2.38-1.033-.9,1.617,2.111,1.549q-.09.382-.146.752a5.068,5.068,0,0,0,0,1.5q.056.371.146.752L81.8,188.035l.9,1.617,2.38-1.033a5.754,5.754,0,0,0,1.2.921,5.3,5.3,0,0,0,1.4.561Z" transform="translate(-72.981 -168.981)" fill="#394676"/>
          </g>
        </g>
      </g>
    </g>
    <line id="Line_1351" data-name="Line 1351" x1="1380" transform="translate(10 50)" fill="none" stroke="#2f385a" stroke-width="1"/>
    <line id="Line_1353" data-name="Line 1353" x1="220" transform="translate(10 136)" fill="none" stroke="#2f385a" stroke-width="1"/>
    <line id="Line_1352" data-name="Line 1352" x1="220" transform="translate(10 619)" fill="none" stroke="#2f385a" stroke-width="1"/>
    <g id="Group_5036" data-name="Group 5036" transform="translate(2 1.537)">
      <circle id="Ellipse_118" data-name="Ellipse 118" cx="6" cy="6" r="6" transform="translate(22 22.463)" fill="#2bfaa2"/>
      <ellipse id="Ellipse_119" data-name="Ellipse 119" cx="6.5" cy="6" rx="6.5" ry="6" transform="translate(39 22.463)" fill="#fd9b51"/>
      <ellipse id="Ellipse_120" data-name="Ellipse 120" cx="6.5" cy="6" rx="6.5" ry="6" transform="translate(56 22.463)" fill="#4836ff"/>
    </g>
    <g id="Group_5037" data-name="Group 5037">
      <g id="Group_4846" data-name="Group 4846" transform="translate(10 153)">
        <g id="Group_4671" data-name="Group 4671" transform="translate(20.883 10.961)">
          <g id="Medium_Icon_Button_:default-2" data-name="Medium Icon Button :default" transform="translate(0.117 0.039)">
            <rect id="base-2" data-name="base" width="32" height="32" rx="16" fill="#4835fb"/>
          </g>
          <path id="home_FILL0_wght400_GRAD0_opsz48" d="M161.484,232.327H165.2v-6.185h5.442v6.185h3.711v-9.648l-6.432-4.824-6.432,4.824ZM160,233.812V221.937L167.916,216l7.916,5.937v11.874h-6.679v-6.185h-2.474v6.185ZM167.916,225.079Z" transform="translate(-152.16 -209.149)" fill="#fff"/>
        </g>
        <text id="Mail_Settings-2" data-name="Mail Settings" transform="translate(65 34)" fill="#fff" font-size="19" font-family="FKDisplay-Regular, FK Display, FKDisplayAlt"><tspan x="0" y="0">Dashboard</tspan></text>
        <g id="Group_4676-2" data-name="Group 4676">
          <rect id="Rectangle_1827-2" data-name="Rectangle 1827" width="220" height="55" fill="none"/>
        </g>
      </g>
      <g id="Group_4860" data-name="Group 4860" transform="translate(10 263.057)">
        <g id="Group_4671-2" data-name="Group 4671" transform="translate(22.073 10.366)">
          <g id="Medium_Icon_Button_:default-3" data-name="Medium Icon Button :default" transform="translate(-0.073 -0.423)">
            <rect id="base-3" data-name="base" width="32" height="32" rx="16" fill="#192038"/>
          </g>
          <path id="auto_graph_FILL0_wght400_GRAD0_opsz48" d="M47.009,181.607l-.894-1.909-1.909-.894,1.909-.894L47.009,176l.894,1.909,1.909.894L47.9,179.7Zm6.526,2.924-.749-1.6-1.6-.749,1.6-.749.749-1.6.749,1.6,1.6.749-1.6.749Zm-11.19,1.933-.749-1.6-1.6-.749,1.6-.749.749-1.6.749,1.6,1.6.749-1.6.749Zm.7,7.42-1.112-1.112,7.251-7.251,3.867,3.891,7.2-8.072,1.015.991-8.217,9.257-3.867-3.843Z" transform="translate(-34.868 -168.618)" fill="#394676"/>
        </g>
        <text id="Monitors" transform="translate(65 31.943)" fill="#394676" font-size="17" font-family="FKDisplay-Regular, FK Display, FKDisplayAlt" letter-spacing="-0.015em"><tspan x="0" y="0">Monitors</tspan></text>
        <g id="Group_4676-3" data-name="Group 4676" transform="translate(0 0.301)">
          <rect id="Rectangle_1827-3" data-name="Rectangle 1827" width="220" height="55" transform="translate(0 -0.357)" fill="none"/>
        </g>
      </g>
      <g id="Group_4862" data-name="Group 4862" transform="translate(10 208.042)">
        <text id="Analytics" transform="translate(65 34.958)" fill="#394676" font-size="19" font-family="FKDisplay-Regular, FK Display, FKDisplayAlt" letter-spacing="-0.015em"><tspan x="0" y="0">Analytics</tspan></text>
        <rect id="Rectangle_1827-4" data-name="Rectangle 1827" width="220" height="55" transform="translate(0 -0.042)" fill="none"/>
        <g id="Group_4861" data-name="Group 4861" transform="translate(21 12.378)">
          <path id="base-4" data-name="base" d="M15.8,0A15.8,15.8,0,1,1,0,15.8,15.8,15.8,0,0,1,15.8,0Z" fill="#192038"/>
          <path id="data_usage_FILL0_wght400_GRAD0_opsz48" d="M89.951,195.857a8.661,8.661,0,0,1-3.468-.7,9.175,9.175,0,0,1-2.853-1.913A8.945,8.945,0,0,1,81.7,190.4a8.911,8.911,0,0,1,7.6-12.374V180.3a6.649,6.649,0,0,0,.649,13.27,6.63,6.63,0,0,0,3.043-.727,7.06,7.06,0,0,0,2.439-2.048l1.969,1.141a9.084,9.084,0,0,1-3.256,2.887A8.884,8.884,0,0,1,89.951,195.857Zm8.1-5.124-1.969-1.1a8.191,8.191,0,0,0,.4-1.376,7,7,0,0,0,.134-1.354,6.5,6.5,0,0,0-1.712-4.5,6.281,6.281,0,0,0-4.263-2.126V178a8.823,8.823,0,0,1,8.257,8.817,9.253,9.253,0,0,1-.213,1.98A10.132,10.132,0,0,1,98.051,190.733Z" transform="translate(-74.155 -171.132)" fill="#394676"/>
        </g>
      </g>
      <path id="Rectangle_2263" data-name="Rectangle 2263" d="M0,0H0A3,3,0,0,1,3,3V52a3,3,0,0,1-3,3H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(10 153)" fill="#fd9b51"/>
    </g>
    <line id="Line_1354" data-name="Line 1354" y1="86" transform="translate(230 50)" fill="none" stroke="#2f385a" stroke-width="1"/>
    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Rectangle_2213)">
      <rect id="Rectangle_2213-2" data-name="Rectangle 2213" width="340" height="200" rx="15" transform="translate(591 353) rotate(180)" fill="#fff"/>
    </g>
    <path id="Path_15706" data-name="Path 15706" d="M15,0H1069c8.284,0,15,10.082,15,22.518V178.646H0V22.518C0,10.082,6.716,0,15,0Z" transform="translate(251 591.354)" fill="#fff"/>
    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Rectangle_2272)">
      <rect id="Rectangle_2272-2" data-name="Rectangle 2272" width="340" height="200" rx="15" transform="translate(591 573) rotate(180)" fill="#fff"/>
    </g>
    <path id="Path_15705" data-name="Path 15705" d="M65,0A65,65,0,1,1,0,65,65,65,0,0,1,65,0Z" transform="translate(280 197)" fill="#e1ebff"/>
    <path id="Path_15704" data-name="Path 15704" d="M65,0A65,65,0,1,1,0,65,65,65,0,0,1,65,0Z" transform="translate(429 197)" fill="#defee3"/>
    <g id="Group_5116" data-name="Group 5116" transform="translate(-13852.794 7823.938)">
      <g id="Group_5029" data-name="Group 5029" transform="translate(14454.087 -7670.938)">
        <g id="Group_5027" data-name="Group 5027" transform="translate(16 16)">
          <text id="Daily_Active_Users" data-name="Daily Active Users" transform="translate(-0.293 18)" fill="#131330" font-size="19" font-family="Inter" font-weight="600" letter-spacing="-0.015em"><tspan x="0" y="0">Daily Active Users</tspan></text>
        </g>
      </g>
    </g>
    <g id="Group_5121" data-name="Group 5121" transform="translate(-13852.794 8043.938)">
      <g id="Group_5029-2" data-name="Group 5029" transform="translate(14454.087 -7670.938)">
        <g id="Group_5027-2" data-name="Group 5027" transform="translate(16 16)">
          <text id="Daily_New_Users" data-name="Daily New Users" transform="translate(-0.293 18)" fill="#131330" font-size="19" font-family="Inter" font-weight="600" letter-spacing="-0.015em"><tspan x="0" y="0">Daily New Users</tspan></text>
        </g>
      </g>
    </g>
    <g id="Group_5124" data-name="Group 5124" transform="translate(-9.399 -58)">
      <path id="Rectangle_2250" data-name="Rectangle 2250" d="M524.244,44h0a8.238,8.238,0,0,1,8.244,8.244v121.31a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,516,173.554V52.244A8.238,8.238,0,0,1,524.244,44Z" transform="translate(335.578 210.55)" fill="url(#linear-gradient-2)"/>
      <path id="Rectangle_2253" data-name="Rectangle 2253" d="M653.244,44h0a8.238,8.238,0,0,1,8.244,8.244v121.31a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,645,173.554V52.244A8.238,8.238,0,0,1,653.244,44Z" transform="translate(324.282 210.55)" fill="url(#linear-gradient-2)"/>
      <path id="Rectangle_2257" data-name="Rectangle 2257" d="M825.244,10h0c4.558,0,8.244,3.149,8.244,7.043V154.889c0,3.894-3.686,7.043-8.244,7.043h0c-4.558,0-8.244-3.149-8.244-7.043V17.043C817,13.149,820.686,10,825.244,10Z" transform="translate(309.222 230.417)" fill="url(#linear-gradient-4)"/>
      <path id="Rectangle_2260" data-name="Rectangle 2260" d="M954.244,10h0c4.558,0,8.244,3.149,8.244,7.043V154.889c0,3.894-3.686,7.043-8.244,7.043h0c-4.558,0-8.244-3.149-8.244-7.043V17.043C946,13.149,949.686,10,954.244,10Z" transform="translate(297.925 230.417)" fill="url(#linear-gradient-4)"/>
      <path id="Rectangle_2260-2" data-name="Rectangle 2260" d="M954.244,60h0c4.558,0,8.244,3.149,8.244,7.043v87.846c0,3.894-3.686,7.043-8.244,7.043h0c-4.558,0-8.244-3.149-8.244-7.043V67.043C946,63.149,949.686,60,954.244,60Z" transform="translate(-251.362 230.417)" fill="url(#linear-gradient-4)"/>
      <path id="Rectangle_2258" data-name="Rectangle 2258" d="M868.244,10h0c4.558,0,8.244,3.149,8.244,7.043V154.889c0,3.894-3.686,7.043-8.244,7.043h0c-4.558,0-8.244-3.149-8.244-7.043V17.043C860,13.149,863.686,10,868.244,10Z" transform="translate(305.455 230.417)" fill="url(#linear-gradient-4)"/>
      <path id="Rectangle_2251-2" data-name="Rectangle 2251" d="M567.244,57h0a8.238,8.238,0,0,1,8.244,8.244v106a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,559,171.243v-106A8.238,8.238,0,0,1,567.244,57Z" transform="translate(331.813 212.861)" fill="url(#linear-gradient-8)"/>
      <path id="Rectangle_2254" data-name="Rectangle 2254" d="M696.244,57h0a8.238,8.238,0,0,1,8.244,8.244v106a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,688,171.243v-106A8.238,8.238,0,0,1,696.244,57Z" transform="translate(320.518 212.861)" fill="url(#linear-gradient-8)"/>
      <path id="Rectangle_2261" data-name="Rectangle 2261" d="M997.244,57h0a8.238,8.238,0,0,1,8.244,8.244v106a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,989,171.243v-106A8.238,8.238,0,0,1,997.244,57Z" transform="translate(294.159 212.861)" fill="url(#linear-gradient-8)"/>
      <path id="Rectangle_2255" data-name="Rectangle 2255" d="M782.244,57h0a8.238,8.238,0,0,1,8.244,8.244v106a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,774,171.243v-106A8.238,8.238,0,0,1,782.244,57Z" transform="translate(312.986 212.861)" fill="url(#linear-gradient-8)"/>
      <path id="Rectangle_2252" data-name="Rectangle 2252" d="M610.244,57h0a8.238,8.238,0,0,1,8.244,8.244v106a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,602,171.243v-106A8.238,8.238,0,0,1,610.244,57Z" transform="translate(328.048 212.861)" fill="url(#linear-gradient-8)"/>
      <path id="Rectangle_2252-2" data-name="Rectangle 2252" d="M610.244,57h0a8.238,8.238,0,0,1,8.244,8.244v106a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,602,171.243v-106A8.238,8.238,0,0,1,610.244,57Z" transform="translate(131.874 212.861)" fill="url(#linear-gradient-8)"/>
      <path id="Rectangle_2252-3" data-name="Rectangle 2252" d="M610.244,57h0a8.238,8.238,0,0,1,8.244,8.244v106a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,602,171.243v-106A8.238,8.238,0,0,1,610.244,57Z" transform="translate(53.149 212.861)" fill="url(#linear-gradient-8)"/>
      <path id="Rectangle_2252-4" data-name="Rectangle 2252" d="M610.244,57h0a8.238,8.238,0,0,1,8.244,8.244v106a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,602,171.243v-106A8.238,8.238,0,0,1,610.244,57Z" transform="translate(210.598 212.861)" fill="url(#linear-gradient-8)"/>
      <path id="Rectangle_2256" data-name="Rectangle 2256" d="M739.244,60h0a8.238,8.238,0,0,1,8.244,8.244v91.8a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,731,160.044v-91.8A8.238,8.238,0,0,1,739.244,60Z" transform="translate(316.751 224.061)" fill="url(#linear-gradient-16)"/>
      <path id="Rectangle_2256-2" data-name="Rectangle 2256" d="M739.244,120h0a8.238,8.238,0,0,1,8.244,8.244v31.8a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,731,160.044v-31.8A8.238,8.238,0,0,1,739.244,120Z" transform="translate(42.109 224.061)" fill="url(#linear-gradient-16)"/>
      <path id="Rectangle_2259" data-name="Rectangle 2259" d="M911.244,120h0a8.238,8.238,0,0,1,8.244,8.244v31.8a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,903,160.044v-31.8A8.238,8.238,0,0,1,911.244,120Z" transform="translate(301.69 224.061)" fill="url(#linear-gradient-16)"/>
    </g>
    <g id="Group_5125" data-name="Group 5125" transform="translate(-9.399 159.083)">
      <path id="Rectangle_2250-2" data-name="Rectangle 2250" d="M524.244,44h0a8.238,8.238,0,0,1,8.244,8.244v121.31a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,516,173.554V52.244A8.238,8.238,0,0,1,524.244,44Z" transform="translate(335.578 210.55)" fill="#fd9b51"/>
      <path id="Rectangle_2253-2" data-name="Rectangle 2253" d="M653.244,44h0a8.238,8.238,0,0,1,8.244,8.244v121.31a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,645,173.554V52.244A8.238,8.238,0,0,1,653.244,44Z" transform="translate(324.282 210.55)" fill="#fd9b51"/>
      <path id="Rectangle_2257-2" data-name="Rectangle 2257" d="M825.244,10h0c4.558,0,8.244,3.149,8.244,7.043V154.889c0,3.894-3.686,7.043-8.244,7.043h0c-4.558,0-8.244-3.149-8.244-7.043V17.043C817,13.149,820.686,10,825.244,10Z" transform="translate(309.222 230.417)" fill="#fd9b51"/>
      <path id="Rectangle_2260-3" data-name="Rectangle 2260" d="M954.244,10h0c4.558,0,8.244,3.149,8.244,7.043V154.889c0,3.894-3.686,7.043-8.244,7.043h0c-4.558,0-8.244-3.149-8.244-7.043V17.043C946,13.149,949.686,10,954.244,10Z" transform="translate(297.925 230.417)" fill="#fd9b51"/>
      <path id="Rectangle_2260-4" data-name="Rectangle 2260" d="M954.244,60h0c4.558,0,8.244,3.149,8.244,7.043v87.846c0,3.894-3.686,7.043-8.244,7.043h0c-4.558,0-8.244-3.149-8.244-7.043V67.043C946,63.149,949.686,60,954.244,60Z" transform="translate(-251.362 230.417)" fill="#fd9b51"/>
      <path id="Rectangle_2258-2" data-name="Rectangle 2258" d="M868.244,10h0c4.558,0,8.244,3.149,8.244,7.043V154.889c0,3.894-3.686,7.043-8.244,7.043h0c-4.558,0-8.244-3.149-8.244-7.043V17.043C860,13.149,863.686,10,868.244,10Z" transform="translate(305.455 230.417)" fill="#fd9b51"/>
      <path id="Rectangle_2251-3" data-name="Rectangle 2251" d="M567.244,57h0a8.238,8.238,0,0,1,8.244,8.244v106a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,559,171.243v-106A8.238,8.238,0,0,1,567.244,57Z" transform="translate(331.813 212.861)" fill="#fd9b51"/>
      <path id="Rectangle_2254-2" data-name="Rectangle 2254" d="M696.244,57h0a8.238,8.238,0,0,1,8.244,8.244v106a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,688,171.243v-106A8.238,8.238,0,0,1,696.244,57Z" transform="translate(320.518 212.861)" fill="#fd9b51"/>
      <path id="Rectangle_2261-2" data-name="Rectangle 2261" d="M997.244,57h0a8.238,8.238,0,0,1,8.244,8.244v106a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,989,171.243v-106A8.238,8.238,0,0,1,997.244,57Z" transform="translate(294.159 212.861)" fill="#fd9b51"/>
      <path id="Rectangle_2255-2" data-name="Rectangle 2255" d="M782.244,57h0a8.238,8.238,0,0,1,8.244,8.244v106a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,774,171.243v-106A8.238,8.238,0,0,1,782.244,57Z" transform="translate(312.986 212.861)" fill="#fd9b51"/>
      <path id="Rectangle_2252-5" data-name="Rectangle 2252" d="M610.244,57h0a8.238,8.238,0,0,1,8.244,8.244v106a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,602,171.243v-106A8.238,8.238,0,0,1,610.244,57Z" transform="translate(328.048 212.861)" fill="#fd9b51"/>
      <path id="Rectangle_2252-6" data-name="Rectangle 2252" d="M610.244,57h0a8.238,8.238,0,0,1,8.244,8.244v106a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,602,171.243v-106A8.238,8.238,0,0,1,610.244,57Z" transform="translate(131.874 212.861)" fill="#fd9b51"/>
      <path id="Rectangle_2252-7" data-name="Rectangle 2252" d="M610.244,57h0a8.238,8.238,0,0,1,8.244,8.244v106a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,602,171.243v-106A8.238,8.238,0,0,1,610.244,57Z" transform="translate(53.149 212.861)" fill="#fd9b51"/>
      <path id="Rectangle_2252-8" data-name="Rectangle 2252" d="M610.244,57h0a8.238,8.238,0,0,1,8.244,8.244v106a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,602,171.243v-106A8.238,8.238,0,0,1,610.244,57Z" transform="translate(210.598 212.861)" fill="#fd9b51"/>
      <path id="Rectangle_2256-3" data-name="Rectangle 2256" d="M739.244,60h0a8.238,8.238,0,0,1,8.244,8.244v91.8a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,731,160.044v-91.8A8.238,8.238,0,0,1,739.244,60Z" transform="translate(316.751 224.061)" fill="#fd9b51"/>
      <path id="Rectangle_2256-4" data-name="Rectangle 2256" d="M739.244,120h0a8.238,8.238,0,0,1,8.244,8.244v31.8a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,731,160.044v-31.8A8.238,8.238,0,0,1,739.244,120Z" transform="translate(42.109 224.061)" fill="#fd9b51"/>
      <path id="Rectangle_2259-2" data-name="Rectangle 2259" d="M911.244,120h0a8.238,8.238,0,0,1,8.244,8.244v31.8a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,903,160.044v-31.8A8.238,8.238,0,0,1,911.244,120Z" transform="translate(301.69 224.061)" fill="#fd9b51"/>
    </g>
    <g id="Group_5118" data-name="Group 5118" transform="translate(276.293 174)">
      <text id="Users" transform="translate(-4.293 18)" fill="#131330" font-size="19" font-family="Inter" font-weight="600" letter-spacing="-0.015em"><tspan x="0" y="0">Users</tspan></text>
    </g>
    <g id="Group_5123" data-name="Group 5123" transform="translate(276.293 388)">
      <text id="Email_Engagement_Rate" data-name="Email Engagement 
Rate" transform="translate(-4.293 18)" fill="#131330" font-size="19" font-family="Inter" font-weight="600" letter-spacing="-0.015em"><tspan x="0" y="0">Email Engagement </tspan><tspan x="0" y="20">Rate</tspan></text>
    </g>
    <g id="Group_5119" data-name="Group 5119" transform="translate(0 -57)">
      <text id="_108" data-name="108" transform="translate(344 339)" fill="#4836ff" font-size="55" font-family="FKDisplay-Regular, FK Display, FKDisplayAlt" font-weight="600" letter-spacing="-0.03em"><tspan x="-45.1" y="0">108</tspan></text>
      <text id="_33_" data-name="33%" transform="translate(345 363)" fill="#192038" font-size="18" font-family="FKDisplay-Regular, FK Display, FKDisplayAlt" font-weight="600"><tspan x="-17.775" y="0">33%</tspan></text>
      <text id="TOTAL" transform="translate(344 289)" fill="#0b151e" font-size="14" font-family="FKDisplay-Regular, FK Display, FKDisplayAlt" font-weight="600"><tspan x="-20.307" y="0">TOTAL</tspan></text>
    </g>
    <g id="Group_5120" data-name="Group 5120" transform="translate(149 -57)">
      <text id="_32" data-name="32" transform="translate(344 339)" fill="#32e04d" font-size="55" font-family="FKDisplay-Regular, FK Display, FKDisplayAlt" font-weight="700" letter-spacing="-0.03em"><tspan x="-32.23" y="0">32</tspan></text>
      <text id="_17_" data-name="17%" transform="translate(345 362)" fill="#192038" font-size="18" font-family="FKDisplay-Regular, FK Display, FKDisplayAlt" font-weight="600"><tspan x="-15.849" y="0">17%</tspan></text>
      <text id="New" transform="translate(344 289)" fill="#0b151e" font-size="14" font-family="FKDisplay-Regular, FK Display, FKDisplayAlt" font-weight="600"><tspan x="-14.812" y="0">NEW</tspan></text>
    </g>
    <rect id="Rectangle_2424" data-name="Rectangle 2424" width="178" height="23" rx="11.5" transform="translate(272 619)" fill="#f4f9ff"/>
    <rect id="Rectangle_2275" data-name="Rectangle 2275" width="563" height="23" rx="11.5" transform="translate(272 721)" fill="#f4f9ff"/>
    <rect id="Rectangle_2425" data-name="Rectangle 2425" width="563" height="23" rx="11.5" transform="translate(272 686)" fill="#f4f9ff"/>
    <rect id="Rectangle_2276" data-name="Rectangle 2276" width="456" height="23" rx="11.5" transform="translate(850 721)" fill="#f4f9ff"/>
    <g id="Group_5106" data-name="Group 5106" transform="translate(-394 18)">
      <g id="Group_5103" data-name="Group 5103" transform="translate(11 7)">
        <rect id="Rectangle_2267" data-name="Rectangle 2267" width="145" height="23" rx="11.5" transform="translate(659 464)" fill="#e1ebff"/>
        <rect id="Rectangle_2268" data-name="Rectangle 2268" width="145" height="23" rx="11.5" transform="translate(659 426)" fill="#e1ebff"/>
        <g id="Group_5102" data-name="Group 5102" transform="translate(-375 -7.996)">
          <g id="Group_5084" data-name="Group 5084" transform="translate(1196 392.993)">
            <g id="Group_4995" data-name="Group 4995" transform="translate(0 0.008)">
              <g id="Group_5002" data-name="Group 5002">
                <g id="Group_5001" data-name="Group 5001" clip-path="url(#clip-path-3)">
                  <path id="Path_15681" data-name="Path 15681" d="M85,.5Z" transform="translate(-18.002 -0.106)" fill="url(#linear-gradient-19)"/>
                  <path id="Path_15682" data-name="Path 15682" d="M75.469,40A35.47,35.47,0,1,1,40,75.471,35.469,35.469,0,0,1,75.469,40" transform="translate(-8.471 -8.472)" fill="#fff"/>
                </g>
              </g>
              <circle id="Ellipse_123" data-name="Ellipse 123" cx="67" cy="67" r="67" transform="translate(0 0)" fill="url(#radial-gradient)"/>
            </g>
            <path id="Path_15701" data-name="Path 15701" d="M85,.5a66.534,66.534,0,0,1,65.912,57.451L97.088,65.324A12.214,12.214,0,0,0,85,54.826V.5Z" transform="translate(-17.595 -0.501)" fill="url(#linear-gradient-19)"/>
            <circle id="Ellipse_122" data-name="Ellipse 122" cx="36" cy="36" r="36" transform="translate(31 31.007)" fill="url(#linear-gradient-21)"/>
          </g>
          <text id="_76_" data-name="76%" transform="translate(1263 467)" fill="#fff" font-size="22" font-family="FKDisplay-Regular, FK Display, FKDisplayAlt" font-weight="700" letter-spacing="-0.03em"><tspan x="-20.526" y="0">76%</tspan></text>
          <rect id="Rectangle_2270" data-name="Rectangle 2270" width="118" height="23" rx="11.5" transform="translate(1060 472)" fill="#e1ebff"/>
          <rect id="Rectangle_2269" data-name="Rectangle 2269" width="118" height="23" rx="11.5" transform="translate(1060 434)" fill="#e1ebff"/>
        </g>
      </g>
    </g>
  </g>
</svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
