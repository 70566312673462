import React, { Component } from 'react';
import classnames from 'classnames';

import Divider from '../Divider';

import DevOps from './Images/Section3/DevOps';
import Product from './Images/Section3/Product';
import Security from './Images/Section3/Security';

import styles from 'styles/Marketing/Root/Section3';

export default class Section3 extends Component {
  render() {
    return (
      <div className={classnames(styles.Container)}>
        <div className={classnames(styles.MaxWidth, 'mx-auto', 'px-2', 'px-lg-4', 'position-relative')} style={{zIndex: '13'}}>
          <div className={classnames('px-lg-5', 'pt-5', 'px-0')}>
            <h3 className={classnames(styles.h3, 'mb-3')}>Don't let email catch you by surprise — <br className='d-lg-block d-none'/>stay one step ahead with real-time insights.</h3>

            <div className={classnames(styles.Personas, 'row')}>
              <div className='col-lg-4'>
                <div className={classnames(styles.Card1, 'p-3')}>
                  <h4 className={classnames(styles.callout, styles.ColorPrimaryPurple, 'mb-2')}>DELIVERY</h4>
                  <div className='text-center'>
                    <DevOps className='mb-2' />
                  </div>
                  <h5 className={classnames(styles.h5Span, 'mb-2')}>Optimize delivery<br/>at every stage</h5>
                  <p className={classnames(styles.p2, 'mb-0')}>From warm-up to scale, level up your deliverability and add redundancy to your infrastructure with intelligent routing and unlimited dedicated IPs.</p>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className={classnames(styles.Card1, 'p-3')}>
                  <h4 className={classnames(styles.callout, styles.ColorPrimaryPurple, 'mb-2')}>VISIBILITY</h4>
                  <div className='text-center'>
                    <Product className='mb-2' />
                  </div>
                  <h5 className={classnames(styles.h5Span, 'mb-2')}>Gain full visibility<br/>with monitoring</h5>
                  <p className={classnames(styles.p2, 'mb-0')}>Stay ahead of emergencies and be confident about your sending health with real-time, customizable alerts.</p>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className={classnames(styles.Card1, 'p-3')}>
                  <h4 className={classnames(styles.callout, styles.ColorPrimaryPurple, 'mb-2')}>ANALYTICS</h4>
                  <div className='text-center'>
                    <Security className='mb-2' />
                  </div>
                  <h5 className={classnames(styles.h5Span, 'mb-2')}>Open a new lens<br />into user activity</h5>
                  <p className={classnames(styles.p2, 'mb-0')}>Enhance your product with usage analytics derived from live user engagement data.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Divider style={{bottom: '116px'}} />
      </div>
    );
  }
}
