import React, { Component } from 'react';
import classnames from 'classnames';

import Orbs from 'components/Util/Misc/Orbs';

import styles from 'styles/Marketing/Root/Section4';

export default class Section4 extends Component {
  render() {
    const orbs = [
      {
        width: '376px',
        height: '376px',
        top: '88px',
        left: 'calc(50% - 320px)',
        background: styles.colorPrimaryPurple,
      },
      {
        width: '150px',
        height: '150px',
        top: '496px',
        right: 'calc(50% - 330px)',
        background: styles.colorPrimaryPurple,
      },
      {
        width: '180px',
        height: '180px',
        top: '260px',
        right: 'calc(50% - 590px)',
        background: styles.colorSecondaryOrange,
      },
    ];

    return (
      <div className='position-relative' style={{ marginTop: '-116px' }}>
        <Orbs orbs={orbs} blurEffect={false}>
          <div className={classnames(styles.MaxWidth, 'mx-auto')}>
            <h2 className={classnames(styles.h2Span, styles.ColorPrimaryWhite, styles.Header)}>Scale Confidently <br className='d-none d-lg-inline' />With Greater Data <br className='d-none d-lg-inline'/>Transparency Into Your <br className='d-none d-lg-inline'/>Sending Infrastructure</h2>
          </div>
        </Orbs>
      </div>
    );
  }
}
