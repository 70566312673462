import React, { Component } from 'react';
import classnames from 'classnames';

import Devops from './Images/Section5/Devops';
import Product from './Images/Section5/Product';
import IntelligentRouting from './Images/Section5/IntelligentRouting';

import styles from 'styles/Marketing/Root/Section5';

export default class Section5 extends Component {
  render() {
    return (
      <div className={classnames(styles.Container, 'position-relative')}>
        <h4 className={classnames(styles.h4, styles.ColorPrimaryWhite, 'mb-4', 'text-center')}>API-powered features <br />unique to Amply:</h4>
        <div className={classnames(styles.MaxWidth, 'mx-auto', 'px-2', 'px-lg-4')}>

          <div className={classnames(styles.Rows, 'pt-4')}>
            <div className={classnames('row', 'd-flex', 'flex-lg-row-reverse')}>
              <div className='col-lg-6 pt-lg-4'>
                <div className={classnames('d-flex', 'flex-column', 'justify-content-center')}>
                  <h4 className={classnames(styles.callout, styles.ColorPrimaryPurple, 'mb-2')}>DELIVERY</h4>
                  <h4 id='delivery' className={classnames(styles.h4, 'mb-2')}>Get 2x deliverability rates <br /> with intelligent routing</h4>
                  <p className={classnames(styles.p2)}>We've re-engineered email delivery with intelligent routing, which automatically picks the best IPs to send your messages based on real-time signals in your sending data. With more IPs, you gain built-in redundancy that shields you from spam traps, sudden volume spikes, and reputational issues — and real-time failover means your messages can still get delivered even if your IP's reputation has been damaged. Say goodbye to deliverability challenges and manually managing IPs as you scale.</p>
                  <p className={classnames(styles.p2, 'fst-italic')}>Delivery powered by intelligence eliminates the guesswork and saves you from endless headaches.</p>
                </div>
              </div>
              <div className='col-lg-6'>
                <img src={IntelligentRouting} />
              </div>
            </div>

            <div className={classnames('row', styles.Margin)}>
              <div className='col-lg-5 pt-lg-4'>
                <div className={classnames('d-flex', 'flex-column', 'justify-content-center')}>
                  <h4 id='visibility' className={classnames(styles.callout, styles.ColorPrimaryPurple, 'mb-2')}>VISIBILITY</h4>
                  <h4 className={classnames(styles.h4, 'mb-2')}>Prevent disruptions before they <br />impact users with monitoring</h4>
                  <p className={classnames(styles.p2)}>Don’t let your users be the first to notice sending issues. With customizable alerts, you can keep track of blocklists, IP and domain reputation changes, bounce spikes, unsubscribes, and spam concerns early. Amply enables you to pinpoint and resolve incidents before they escalate into bigger problems.</p>
                  <p className={classnames(styles.p2, 'fst-italic')}>Stay in control of your delivery infrastructure with proactive monitoring. Detect and address issues instantly to ensure uninterrupted, reliable communication.</p>
                </div>
              </div>
              <div className='col-lg-7'>
                <img src={Devops} />
              </div>
            </div>

            <div className={classnames('row', 'd-flex', 'flex-lg-row-reverse')}>
              <div className='col-lg-5 pt-lg-4'>
                <div className={classnames('d-flex', 'flex-column', 'justify-content-center')}>
                  <h4 id='analytics' className={classnames(styles.callout, styles.ColorPrimaryPurple, 'mb-2')}>ANALYTICS</h4>
                  <h4 className={classnames(styles.h4, 'mb-2')}>Unlock key insights <br className='d-none d-lg-inline' />into user activity</h4>
                  <p className={classnames(styles.p2)}>Amply transforms email engagement data into unique insights, highlighting key user events like sign-ups, payments, etc. Discover your most engaged users and those driving long-term retention. For quick searches and deeper analysis, leverage our powerful SQL interface, which can be integrated directly with your product and internal tools.</p>
                  <p className={classnames(styles.p2, 'fst-italic')}>With 2 years of data storage, you’ll gain a comprehensive, long-term view of user activity and growth — empowering you to make informed, data-driven decisions.</p>
                </div>
              </div>

              <div className='col-lg-7'>
                <img src={Product} />
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}
