import React, { Component } from 'react';
import classnames from 'classnames';

import Orbs from 'components/Util/Misc/Orbs';
import Plus from 'components/Util/Icons/Plus';

import LargeSecondaryButton from 'components/Util/Buttons/DarkBackground/LargeSecondaryButton';
import LongArrow from 'components/Util/Icons/LongArrow';

import styles from 'styles/Marketing/Root/Section1';

export default class Section1 extends Component {
  onClick = (id, headerOffset) => {
    const element = document.getElementById(id);
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
         top: offsetPosition,
         behavior: "smooth"
    });
  }

  render() {
    const orbs = [
      // PURPLE ORBS
      // bottom right
      { 
        width: '700px',
        height: '700px',
        bottom: '-400px',
        right: 'calc(50% - 900px)',
        background: styles.colorPrimaryPurple,
        animationDelay: '-0.5s',
        animationDuration: '8s',
      },
      // bottom left
      // ORANGE ORB
      { 
        width: '200px',
        height: '200px',
        bottom: '175px',
        right: 'calc(50% - 560px)',
        background: styles.colorSecondaryOrange,
        animationDelay: '-5s',
        animationDuration: '8s',
      },
      // LIGHT BLUE ORB
      {
        width: '75px',
        height: '75px',
        top: '85px',
        right: 'calc(50% - 570px)',
        background: '#18cefe',
        animationDelay: '-3.2s',
        animationDuration: '8s',
      },
    ];

    const plusColor = `rgba(255,255,255, 0.9)`;

    return (
      <div className='position-relative'>
        <Orbs orbs={orbs} blurEffect={false}>
          <div className='px-0 px-lg-4 position-relative'>
            <div className={classnames(styles.Content, styles.Fold, 'position-relative', 'text-center', 'text-lg-start', )}>
              <div>
                <h1 className={classnames(styles.h1, styles.ColorPrimaryWhite, 'mb-3')}>Email Delivery, Refactored</h1>
                <h2 className={classnames(styles.h2, styles.ColorPrimaryWhite, 'mb-3')}>Un-matched reliability at scale, <br className='d-none d-lg-inline' /> enhanced by your sending data</h2>

                <div onClick={() => this.onClick('delivery', 220)} className={classnames(styles.Card4, styles.Card, styles.CardDelivery, 'ms-lg-0', 'mb-3', 'mb-lg-0')}>
                  <button>Intelligent Email Routing</button>
                  <p>with unlimited dedicated IPs to increase deliverability by up to 2x</p>
                </div>

                <div onClick={() => this.onClick('visibility', 290)} className={classnames(styles.Card4, styles.Card, styles.CardVisibility, 'mb-3', 'mb-lg-0')}>
                  <button>Sending Health Monitors</button>
                  <p>to detect sending disruptions early - spam, bounces, unsubscribes, etc</p>
                </div>

                <div onClick={() => this.onClick('analytics', 250)} className={classnames(styles.Card4, styles.Card, styles.CardAnalytics, 'mb-4', 'mb-lg-0')}>
                  <button>Actionable User Analytics</button>
                  <p>with 20x more data access to better understand user activity</p>
                </div>


              </div>
            </div>
          </div>
        </Orbs>

        <div className={classnames(styles.Aura, styles.MaxWidth, 'mx-auto')} />
      </div>
    );
  }
}
