import React, { Component } from 'react';
import classnames from 'classnames';

import Orbs from 'components/Util/Misc/Orbs';

import styles from 'styles/Base';

export default class New extends Component {
  render() {
    const orbs = [
      // ORANGE ORB
      // right
      {
        width: '200px',
        height: '225px',
        top: '120px',
        right: 'calc(50% - 250px)',
        background: styles.colorSecondaryOrange,
        animationDelay: '-4.5s',
        animationDuration: '8s',
      },
      // PURPLE ORBS
      // top left
      {
        width: '600px',
        height: '600px',
        top: '-500px',
        right: 'calc(50% + 340px)',
        background: styles.colorPrimaryPurple,
        animationDuration: '8s',
      },
    ];

    return (
      <Orbs orbs={orbs} blurEffect={false}>
        <div className={classnames(styles.Container, 'py-5', 'my-3')}>
          <div className={classnames(styles.Card1, 'mx-auto', 'p-3')} style={{ maxWidth: '450px' }}>
            {this.props.children}
          </div>
        </div>
      </Orbs>
    );
  }
}
