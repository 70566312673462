import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

export default class RouteEmail extends Component {
  render() {
    const src = `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1400" height="780" viewBox="0 0 1400 780">
  <defs>
    <linearGradient id="linear-gradient" x1="0.5" y1="0.244" x2="0.916" y2="0.886" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#273257"/>
      <stop offset="1" stop-color="#192038"/>
    </linearGradient>
    <linearGradient id="linear-gradient-2" x1="0.5" y1="1" x2="0.5" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#fcfcfc"/>
      <stop offset="1" stop-color="#f4f9ff"/>
    </linearGradient>
    <clipPath id="clip-path">
      <rect id="Rectangle_2212" data-name="Rectangle 2212" width="84.122" height="27.6" fill="#fff"/>
    </clipPath>
    <clipPath id="clip-path-2">
      <rect id="Rectangle_76" data-name="Rectangle 76" width="26.992" height="29.629" fill="url(#linear-gradient)"/>
    </clipPath>
    <clipPath id="clip-path-3">
      <rect id="Rectangle_2198" data-name="Rectangle 2198" width="93.26" height="30.598" fill="#fff"/>
    </clipPath>
    <filter id="Rectangle_2213" x="235" y="248" width="664" height="237" filterUnits="userSpaceOnUse">
      <feOffset input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="5" result="blur"/>
      <feFlood flood-color="#e1ebfe"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <clipPath id="clip-path-4">
      <rect width="563" height="87" fill="none"/>
    </clipPath>
    <linearGradient id="linear-gradient-4" x1="1" y1="1" x2="0" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#6834ff"/>
      <stop offset="0" stop-color="#6633fd"/>
      <stop offset="1" stop-color="#4836ff"/>
    </linearGradient>
    <filter id="Rectangle_2213-2" x="235" y="58" width="664" height="209" filterUnits="userSpaceOnUse">
      <feOffset input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="5" result="blur-2"/>
      <feFlood flood-color="#e1ebfe"/>
      <feComposite operator="in" in2="blur-2"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <clipPath id="clip-Homepage__01_Route_Email_Intelligently">
      <rect width="1400" height="780"/>
    </clipPath>
  </defs>
  <g id="Homepage__01_Route_Email_Intelligently" data-name="Homepage_ 01 Route Email Intelligently" clip-path="url(#clip-Homepage__01_Route_Email_Intelligently)">
    <rect width="1400" height="780" fill="#fff"/>
    <rect id="Rectangle_2242" data-name="Rectangle 2242" width="572" height="760" rx="15" transform="translate(818 10)" fill="#e1ebfe"/>
    <path id="Rectangle_2262" data-name="Rectangle 2262" d="M0,0H25A15,15,0,0,1,40,15V745a15,15,0,0,1-15,15H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(830 10)" fill="rgba(198,216,253,0.3)"/>
    <rect id="Rectangle_2248" data-name="Rectangle 2248" width="852" height="746" rx="15" transform="translate(10 24)" fill="url(#linear-gradient-2)"/>
    <g id="Group_5058" data-name="Group 5058" transform="translate(0 61)">
      <rect id="Rectangle_2257" data-name="Rectangle 2257" width="288" height="286" transform="translate(987 199)" fill="#c6d8fd"/>
      <g id="Group_5048" data-name="Group 5048" transform="translate(13 -11)">
        <path id="Rectangle_2256" data-name="Rectangle 2256" d="M0,0H275a0,0,0,0,1,0,0V56a0,0,0,0,1,0,0H10A10,10,0,0,1,0,46V0A0,0,0,0,1,0,0Z" transform="translate(987 192)" fill="rgba(225,235,254,0.61)" style="mix-blend-mode: multiply;isolation: isolate"/>
        <g id="Group_5047" data-name="Group 5047" transform="translate(0 -22)">
          <g id="Group_5043" data-name="Group 5043" transform="translate(955.858 206.14)">
            <g id="Rectangle_2203" data-name="Rectangle 2203" transform="translate(0.141 -0.14)" fill="#fff" stroke="#4835fb" stroke-width="1">
              <rect width="324" height="59" rx="15" stroke="none"/>
              <rect x="0.5" y="0.5" width="323" height="58" rx="14.5" fill="none"/>
            </g>
            <circle id="Ellipse_104" data-name="Ellipse 104" cx="6" cy="6" r="6" transform="translate(289.141 22.86)" fill="#e1ebff"/>
            <circle id="Ellipse_105" data-name="Ellipse 105" cx="6" cy="6" r="6" transform="translate(270.141 22.86)" fill="#e1ebfe"/>
          </g>
          <text id="Application" transform="translate(1111 242)" fill="#4836ff" font-size="16" font-family="Inter"><tspan x="-42.193" y="0">Application</tspan></text>
        </g>
      </g>
      <g id="Group_5057" data-name="Group 5057" transform="translate(13 217)">
        <path id="Rectangle_2256-2" data-name="Rectangle 2256" d="M0,0H275a0,0,0,0,1,0,0V56a0,0,0,0,1,0,0H10A10,10,0,0,1,0,46V0A0,0,0,0,1,0,0Z" transform="translate(987 192)" fill="rgba(225,235,254,0.61)" style="mix-blend-mode: multiply;isolation: isolate"/>
        <g id="Group_5047-2" data-name="Group 5047" transform="translate(0 -22)">
          <g id="Group_5043-2" data-name="Group 5043" transform="translate(955.858 206.14)">
            <g id="Rectangle_2203-2" data-name="Rectangle 2203" transform="translate(0.141 -0.14)" fill="#fff" stroke="#4835fb" stroke-width="1">
              <rect width="324" height="59" rx="15" stroke="none"/>
              <rect x="0.5" y="0.5" width="323" height="58" rx="14.5" fill="none"/>
            </g>
          </g>
          <text id="End_User" data-name="End User" transform="translate(1111 242)" fill="#4836ff" font-size="16" font-family="Inter"><tspan x="-57" y="0">Application User</tspan></text>
        </g>
      </g>
      <g id="Group_5054" data-name="Group 5054" transform="translate(13 -29)">
        <path id="Rectangle_2254" data-name="Rectangle 2254" d="M0,0H122a10,10,0,0,1,10,10V38a18,18,0,0,1-18,18H10A10,10,0,0,1,0,46V0A0,0,0,0,1,0,0Z" transform="translate(987 362)" fill="rgba(225,235,254,0.61)" style="mix-blend-mode: multiply;isolation: isolate"/>
        <g id="Group_5050" data-name="Group 5050" transform="translate(0.004 79)">
          <g id="Group_4856" data-name="Group 4856" transform="translate(955.996 275)">
            <g id="Rectangle_2203-3" data-name="Rectangle 2203" transform="translate(0 0)" fill="#fff" stroke="#4835fb" stroke-width="1">
              <rect width="159" height="59" rx="15" stroke="none"/>
              <rect x="0.5" y="0.5" width="158" height="58" rx="14.5" fill="none"/>
            </g>
            <circle id="Ellipse_104-2" data-name="Ellipse 104" cx="6" cy="6" r="6" transform="translate(132 24)" fill="#4835fb"/>
          </g>
          <text id="Dedicated_IPs" data-name="Dedicated IPs" transform="translate(1023.996 311)" fill="#4836ff" font-size="16" font-family="Inter"><tspan x="-52.364" y="0">Dedicated IPs</tspan></text>
        </g>
      </g>
      <g id="Group_5055" data-name="Group 5055" transform="translate(13 -29)">
        <path id="Rectangle_2246" data-name="Rectangle 2246" d="M0,0H120a0,0,0,0,1,0,0V56a0,0,0,0,1,0,0H10A10,10,0,0,1,0,46V0A0,0,0,0,1,0,0Z" transform="translate(1142 362)" fill="rgba(225,235,254,0.61)" style="mix-blend-mode: multiply;isolation: isolate"/>
        <g id="Group_5052" data-name="Group 5052" transform="translate(165.004 79)">
          <g id="Group_4856-2" data-name="Group 4856" transform="translate(955.996 275)">
            <g id="Rectangle_2203-4" data-name="Rectangle 2203" transform="translate(0 0)" fill="#fff" stroke="#4835fb" stroke-width="1">
              <rect width="159" height="59" rx="15" stroke="none"/>
              <rect x="0.5" y="0.5" width="158" height="58" rx="14.5" fill="none"/>
            </g>
            <circle id="Ellipse_104-3" data-name="Ellipse 104" cx="6" cy="6" r="6" transform="translate(132 24)" fill="#fd9b51"/>
          </g>
          <text id="Shared_IPs" data-name="Shared IPs" transform="translate(1023.996 311)" fill="#4836ff" font-size="16" font-family="Inter"><tspan x="-40.432" y="0">Shared IPs</tspan></text>
        </g>
      </g>
      <g id="Group_5056" data-name="Group 5056" transform="translate(13 -24)">
        <path id="Rectangle_2255" data-name="Rectangle 2255" d="M0,0H275a0,0,0,0,1,0,0V56a0,0,0,0,1,0,0H10A10,10,0,0,1,0,46V0A0,0,0,0,1,0,0Z" transform="translate(987 281)" fill="rgba(225,235,254,0.61)" style="mix-blend-mode: multiply;isolation: isolate"/>
        <g id="Group_5053" data-name="Group 5053" transform="translate(0 -95)">
          <g id="Group_4853" data-name="Group 4853" transform="translate(955.858 368.242)">
            <rect id="Rectangle_2209" data-name="Rectangle 2209" width="324" height="59" rx="15" transform="translate(0.141 -0.242)" fill="#0b151e"/>
          </g>
          <path id="Path_15667" data-name="Path 15667" d="M5.9,0A5.9,5.9,0,1,1,0,5.9,5.9,5.9,0,0,1,5.9,0Z" transform="translate(1244.83 392.399)" fill="#4836ff"/>
          <circle id="Ellipse_108" data-name="Ellipse 108" cx="5.5" cy="5.5" r="5.5" transform="translate(1226 393)" fill="#2bfaa2"/>
          <g id="Group_4858" data-name="Group 4858" transform="translate(1040.647 384.014)">
            <g id="Amply_Logo_White" data-name="Amply Logo White" transform="translate(37.015 0.486)" clip-path="url(#clip-path)">
              <path id="Path_15661" data-name="Path 15661" d="M77.533,19.394a5.513,5.513,0,0,0-5.6,2.365,5.52,5.52,0,0,0-8.6-.722l0-1.377H59.461V35.333h4.195V25.262a2.491,2.491,0,1,1,4.982-.058V35.333h4.2V25.262a2.491,2.491,0,1,1,4.981-.058V35.333h4.195V24.814a5.484,5.484,0,0,0-4.476-5.42" transform="translate(-41.987 -13.696)" fill="#fff"/>
              <rect id="Rectangle_2211" data-name="Rectangle 2211" width="4.195" height="21.637" transform="translate(62.044)" fill="#fff"/>
              <path id="Path_15662" data-name="Path 15662" d="M154.673,19.3a7.976,7.976,0,0,0-4.97,1.736V19.659h-3.866V41.295h4.195V34.2a7.965,7.965,0,0,0,4.642,1.489,8.192,8.192,0,0,0,0-16.382M154.3,32.25a4.529,4.529,0,0,1-4.264-4.754,4.289,4.289,0,1,1,8.527,0A4.529,4.529,0,0,1,154.3,32.25" transform="translate(-102.979 -13.696)" fill="#fff"/>
              <path id="Path_15663" data-name="Path 15663" d="M7.923,19.306a12.885,12.885,0,0,0-6.8,1.922l1.3,3.38a11,11,0,0,1,4.441-1.616c2.275-.269,3.581.584,3.581,1.484-2.346.779-9.271,1.8-10.255,5.3C-.8,33.293,2.329,35.334,4.54,35.334H9.968L7.56,33.959l1.961-5.182a.033.033,0,0,0-.056-.032l-3.56,4.27h0L2.878,31.287l7.71-4.4v8.447h4.05v-9.7c0-5.382-3.406-6.328-6.715-6.328" transform="translate(0 -13.697)" fill="#fff"/>
              <path id="Path_15664" data-name="Path 15664" d="M242.557,20.525l-3.685,10.5-3.684-10.5H230.71l5.923,15.663-2.26,5.974h4.477l8.183-21.637Z" transform="translate(-162.912 -14.562)" fill="#fff"/>
            </g>
            <g id="Group_79" data-name="Group 79" clip-path="url(#clip-path-2)">
              <path id="Path_184" data-name="Path 184" d="M14.775,22.906l8.1-16.86a.11.11,0,0,0-.177-.125L9.554,19.233l.005,0L0,12.51,26.992,0,24.329,29.63Z" transform="translate(0 -0.001)" fill="url(#linear-gradient)"/>
            </g>
          </g>
        </g>
      </g>
    </g>
    <path id="Path_15684" data-name="Path 15684" d="M31.547,73.543H11.1C3.419,73.543.5,68.579.5,63.992.476,63.811.5,33.368.5,10.5A10.154,10.154,0,0,1,10.8.5H31.547" transform="translate(932.303 341.667)" fill="none" stroke="#4835fb" stroke-width="1"/>
    <path id="Path_15689" data-name="Path 15689" d="M30.836,73.543H10.856C3.352,73.543.5,68.579.5,63.992.476,63.811.5,33.368.5,10.5A10.033,10.033,0,0,1,10.563.5H30.836" transform="translate(1327.628 415.71) rotate(180)" fill="none" stroke="#4835fb" stroke-width="1"/>
    <circle id="Ellipse_112" data-name="Ellipse 112" cx="4" cy="4" r="4" transform="translate(1118.942 219.698) rotate(-22.5)" fill="none" stroke="#4835fb" stroke-width="1.077"/>
    <circle id="Ellipse_113" data-name="Ellipse 113" cx="4" cy="4" r="4" transform="translate(1132.605 219.698) rotate(-22.5)" fill="none" stroke="#4835fb" stroke-width="1.077"/>
    <rect id="Rectangle_2247" data-name="Rectangle 2247" width="220" height="760" rx="15" transform="translate(10 10)" fill="#0b151e"/>
    <g id="Amply_Logo_White-2" data-name="Amply Logo White" transform="translate(71 78.402)" clip-path="url(#clip-path-3)">
      <path id="Path_15661-2" data-name="Path 15661" d="M79.5,19.4a6.112,6.112,0,0,0-6.2,2.622,6.119,6.119,0,0,0-9.539-.8L63.747,19.7H59.461V37.074h4.65V25.909a2.762,2.762,0,1,1,5.523-.064V37.074h4.651V25.909a2.761,2.761,0,1,1,5.522-.064V37.074h4.651V25.412A6.079,6.079,0,0,0,79.5,19.4" transform="translate(-40.089 -13.087)" fill="#fff"/>
      <rect id="Rectangle_2197" data-name="Rectangle 2197" width="4.651" height="23.987" transform="translate(68.783)" fill="#fff"/>
      <path id="Path_15662-2" data-name="Path 15662" d="M155.633,19.3a8.842,8.842,0,0,0-5.51,1.924V19.7h-4.286V43.684h4.651V35.815a8.831,8.831,0,0,0,5.146,1.65,9.082,9.082,0,0,0,0-18.162m-.419,14.352a5.021,5.021,0,0,1-4.727-5.271,4.755,4.755,0,1,1,9.454,0,5.021,5.021,0,0,1-4.727,5.271" transform="translate(-98.324 -13.087)" fill="#fff"/>
      <path id="Path_15663-2" data-name="Path 15663" d="M8.784,19.306a14.285,14.285,0,0,0-7.537,2.13l1.438,3.747a12.193,12.193,0,0,1,4.924-1.792c2.522-.3,3.97.648,3.97,1.645-2.6.864-10.278,2-11.369,5.871-1.1,3.906,2.373,6.168,4.824,6.168h6.018l-2.67-1.524,2.174-5.745a.036.036,0,0,0-.062-.035L6.547,34.5h0L3.191,32.589l8.547-4.879v9.365h4.49V26.322c0-5.967-3.776-7.016-7.445-7.016" transform="translate(0 -13.088)" fill="#fff"/>
      <path id="Path_15664-2" data-name="Path 15664" d="M243.844,20.525l-4.085,11.637-4.085-11.637H230.71l6.567,17.364-2.505,6.623h4.964l9.072-23.987Z" transform="translate(-155.547 -13.914)" fill="#fff"/>
    </g>
    <g id="Group_5015" data-name="Group 5015" transform="translate(0 97.729)">
      <g id="Group_5018" data-name="Group 5018" transform="translate(10 220)">
        <g id="Group_4854" data-name="Group 4854" transform="translate(0 0)">
          <text id="Mail_Settings" data-name="Mail Settings" transform="translate(65 33.271)" fill="#fff" font-size="18" font-family="FKDisplay-Regular, FK Display, FKDisplayAlt" letter-spacing="-0.015em"><tspan x="0" y="0">Mail Settings</tspan></text>
          <g id="Group_4676" data-name="Group 4676" transform="translate(0 0.271)">
            <rect id="Rectangle_1827" data-name="Rectangle 1827" width="218" height="55" transform="translate(0 0)" fill="none"/>
          </g>
          <g id="Group_5017" data-name="Group 5017" transform="translate(22 12.271)">
            <g id="Medium_Icon_Button_:default" data-name="Medium Icon Button :default" transform="translate(0 0)">
              <rect id="base" width="32" height="32" rx="16" fill="#4836ff"/>
            </g>
            <path id="settings_FILL0_wght400_GRAD0_opsz48" d="M86.916,193.963l-.449-2.829a6.015,6.015,0,0,1-.9-.427,6.172,6.172,0,0,1-.831-.561l-2.65,1.212L80,187.676l2.425-1.774a2.669,2.669,0,0,1-.056-.46q-.011-.258-.011-.46t.011-.46a2.669,2.669,0,0,1,.056-.46L80,182.287l2.088-3.682,2.65,1.213a6.174,6.174,0,0,1,.831-.561,4.646,4.646,0,0,1,.9-.4L86.916,176h4.132l.449,2.829a7.279,7.279,0,0,1,.909.415,3.866,3.866,0,0,1,.82.573l2.65-1.213,2.088,3.682-2.425,1.729a3.164,3.164,0,0,1,.056.483q.011.258.011.483t-.011.471a3.126,3.126,0,0,1-.056.472l2.425,1.751-2.088,3.682-2.65-1.212a7.453,7.453,0,0,1-.82.573,3.913,3.913,0,0,1-.909.415l-.449,2.829Zm2.066-6.063a2.913,2.913,0,1,0-2.066-.853A2.814,2.814,0,0,0,88.982,187.9Zm0-1.347a1.572,1.572,0,1,1,1.111-.46A1.514,1.514,0,0,1,88.982,186.553ZM88.982,184.982Zm-.988,7.634h1.976l.314-2.515a5.3,5.3,0,0,0,1.4-.561,5.755,5.755,0,0,0,1.2-.921l2.38,1.033.9-1.617-2.111-1.549q.09-.382.146-.752a5.014,5.014,0,0,0,.056-.752,6.246,6.246,0,0,0-.045-.752,4.648,4.648,0,0,0-.157-.752l2.111-1.549-.9-1.617-2.38,1.033a5.005,5.005,0,0,0-1.168-.977,3.787,3.787,0,0,0-1.437-.505l-.314-2.515H87.994l-.314,2.515a4.758,4.758,0,0,0-1.426.539,4.953,4.953,0,0,0-1.179.943l-2.38-1.033-.9,1.617,2.111,1.549q-.09.382-.146.752a5.068,5.068,0,0,0,0,1.5q.056.371.146.752L81.8,188.035l.9,1.617,2.38-1.033a5.754,5.754,0,0,0,1.2.921,5.3,5.3,0,0,0,1.4.561Z" transform="translate(-72.981 -168.981)" fill="#fff"/>
          </g>
        </g>
      </g>
      <path id="Rectangle_2201" data-name="Rectangle 2201" d="M0,0H0A3,3,0,0,1,3,3V52a3,3,0,0,1-3,3H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(10 220.271)" fill="#fd9b51"/>
    </g>
    <g id="Group_5029" data-name="Group 5029" transform="translate(0 131)">
      <g transform="matrix(1, 0, 0, 1, 0, -131)" filter="url(#Rectangle_2213)">
        <rect id="Rectangle_2213-3" data-name="Rectangle 2213" width="634" height="207" rx="15" transform="translate(884 470) rotate(180)" fill="#fff"/>
      </g>
      <g id="Group_5027" data-name="Group 5027" transform="translate(266 148)">
        <text id="Intelligent_Routing" data-name="Intelligent Routing" transform="translate(0 18)" fill="#131330" font-size="19" font-family="Inter" font-weight="600" letter-spacing="-0.015em"><tspan x="0" y="0">Intelligent Routing</tspan></text>
      </g>
    </g>
    <g id="Group_5061" data-name="Group 5061">
      <rect id="Rectangle_2213-4" data-name="Rectangle 2213" width="592" height="168" rx="15" transform="translate(842 654) rotate(180)" fill="#fff"/>
      <rect id="Rectangle_2258" data-name="Rectangle 2258" width="178" height="23" rx="11.5" transform="translate(266 505)" fill="#f4f9ff"/>
      <g id="Repeat_Grid_2" data-name="Repeat Grid 2" transform="translate(266 544)" clip-path="url(#clip-path-4)">
        <g transform="translate(-266 -544)">
          <rect id="Rectangle_2259" data-name="Rectangle 2259" width="563" height="23" rx="11.5" transform="translate(266 544)" fill="#f4f9ff"/>
          <rect id="Rectangle_2260" data-name="Rectangle 2260" width="563" height="23" rx="11.5" transform="translate(266 575)" fill="#f4f9ff"/>
          <rect id="Rectangle_2261" data-name="Rectangle 2261" width="563" height="23" rx="11.5" transform="translate(266 608)" fill="#f4f9ff"/>
        </g>
      </g>
    </g>
    <path id="Path_15703" data-name="Path 15703" d="M15,0H577a15,15,0,0,1,15,15V59a15,15,0,0,1-15,15H15A15,15,0,0,1,0,59V15A15,15,0,0,1,15,0Z" transform="translate(842 748) rotate(180)" fill="#fff"/>
    <g id="Group_5063" data-name="Group 5063" transform="translate(0 188)">
      <rect id="Rectangle_2258-2" data-name="Rectangle 2258" width="181" height="23" rx="11.5" transform="translate(266 504)" fill="#f4f9ff"/>
    </g>
    <g id="Group_5066" data-name="Group 5066" transform="translate(191 188)">
      <rect id="Rectangle_2258-3" data-name="Rectangle 2258" width="181" height="23" rx="11.5" transform="translate(266 504)" fill="#f4f9ff"/>
    </g>
    <g id="Group_5068" data-name="Group 5068" transform="translate(382 188)">
      <rect id="Rectangle_2258-4" data-name="Rectangle 2258" width="181" height="23" rx="11.5" transform="translate(266 504)" fill="#f4f9ff"/>
    </g>
    <path id="Rectangle_2251" data-name="Rectangle 2251" d="M15,0H837a15,15,0,0,1,15,15V40a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V15A15,15,0,0,1,15,0Z" transform="translate(10 10)" fill="#192038"/>
    <line id="Line_1351" data-name="Line 1351" x1="220" transform="translate(10 50)" fill="none" stroke="#2f385a" stroke-width="1"/>
    <line id="Line_1353" data-name="Line 1353" x1="220" transform="translate(10 136)" fill="none" stroke="#2f385a" stroke-width="1"/>
    <line id="Line_1352" data-name="Line 1352" x1="220" transform="translate(10 619)" fill="none" stroke="#2f385a" stroke-width="1"/>
    <g id="Group_5036" data-name="Group 5036" transform="translate(2 1.537)">
      <circle id="Ellipse_118" data-name="Ellipse 118" cx="6" cy="6" r="6" transform="translate(22 22.463)" fill="#2bfaa2"/>
      <ellipse id="Ellipse_119" data-name="Ellipse 119" cx="6.5" cy="6" rx="6.5" ry="6" transform="translate(39 22.463)" fill="#fd9b51"/>
      <ellipse id="Ellipse_120" data-name="Ellipse 120" cx="6.5" cy="6" rx="6.5" ry="6" transform="translate(56 22.463)" fill="#4836ff"/>
    </g>
    <g id="Group_5037" data-name="Group 5037">
      <g id="Group_4846" data-name="Group 4846" transform="translate(10 153)">
        <g id="Group_4671" data-name="Group 4671" transform="translate(20.883 10.961)">
          <g id="Medium_Icon_Button_:default-2" data-name="Medium Icon Button :default" transform="translate(0.117 0.039)">
            <rect id="base-2" data-name="base" width="32" height="32" rx="16" fill="#192038"/>
          </g>
          <path id="home_FILL0_wght400_GRAD0_opsz48" d="M161.484,232.327H165.2v-6.185h5.442v6.185h3.711v-9.648l-6.432-4.824-6.432,4.824ZM160,233.812V221.937L167.916,216l7.916,5.937v11.874h-6.679v-6.185h-2.474v6.185ZM167.916,225.079Z" transform="translate(-152.16 -209.149)" fill="#394676"/>
        </g>
        <text id="Dashboard" transform="translate(65 34)" fill="#394676" font-size="19" font-family="FKDisplay-Regular, FK Display, FKDisplayAlt"><tspan x="0" y="0">Dashboard</tspan></text>
        <g id="Group_4676-2" data-name="Group 4676">
          <rect id="Rectangle_1827-2" data-name="Rectangle 1827" width="220" height="55" fill="none"/>
        </g>
      </g>
      <g id="Group_4860" data-name="Group 4860" transform="translate(10 263.057)">
        <g id="Group_4671-2" data-name="Group 4671" transform="translate(22.073 10.366)">
          <g id="Medium_Icon_Button_:default-3" data-name="Medium Icon Button :default" transform="translate(-0.073 -0.423)">
            <rect id="base-3" data-name="base" width="32" height="32" rx="16" fill="#192038"/>
          </g>
          <path id="auto_graph_FILL0_wght400_GRAD0_opsz48" d="M47.009,181.607l-.894-1.909-1.909-.894,1.909-.894L47.009,176l.894,1.909,1.909.894L47.9,179.7Zm6.526,2.924-.749-1.6-1.6-.749,1.6-.749.749-1.6.749,1.6,1.6.749-1.6.749Zm-11.19,1.933-.749-1.6-1.6-.749,1.6-.749.749-1.6.749,1.6,1.6.749-1.6.749Zm.7,7.42-1.112-1.112,7.251-7.251,3.867,3.891,7.2-8.072,1.015.991-8.217,9.257-3.867-3.843Z" transform="translate(-34.868 -168.618)" fill="#394676"/>
        </g>
        <text id="Monitors" transform="translate(65 31.943)" fill="#394676" font-size="17" font-family="FKDisplay-Regular, FK Display, FKDisplayAlt" letter-spacing="-0.015em"><tspan x="0" y="0">Monitors</tspan></text>
        <g id="Group_4676-3" data-name="Group 4676" transform="translate(0 0.301)">
          <rect id="Rectangle_1827-3" data-name="Rectangle 1827" width="220" height="55" transform="translate(0 -0.357)" fill="none"/>
        </g>
      </g>
      <g id="Group_4862" data-name="Group 4862" transform="translate(10 208.042)">
        <text id="Analytics" transform="translate(65 34.958)" fill="#394676" font-size="19" font-family="FKDisplay-Regular, FK Display, FKDisplayAlt" letter-spacing="-0.015em"><tspan x="0" y="0">Analytics</tspan></text>
        <rect id="Rectangle_1827-4" data-name="Rectangle 1827" width="220" height="55" transform="translate(0 -0.042)" fill="none"/>
        <g id="Group_4861" data-name="Group 4861" transform="translate(21 12.378)">
          <path id="base-4" data-name="base" d="M15.8,0A15.8,15.8,0,1,1,0,15.8,15.8,15.8,0,0,1,15.8,0Z" fill="#192038"/>
          <path id="data_usage_FILL0_wght400_GRAD0_opsz48" d="M89.951,195.857a8.661,8.661,0,0,1-3.468-.7,9.175,9.175,0,0,1-2.853-1.913A8.945,8.945,0,0,1,81.7,190.4a8.911,8.911,0,0,1,7.6-12.374V180.3a6.649,6.649,0,0,0,.649,13.27,6.63,6.63,0,0,0,3.043-.727,7.06,7.06,0,0,0,2.439-2.048l1.969,1.141a9.084,9.084,0,0,1-3.256,2.887A8.884,8.884,0,0,1,89.951,195.857Zm8.1-5.124-1.969-1.1a8.191,8.191,0,0,0,.4-1.376,7,7,0,0,0,.134-1.354,6.5,6.5,0,0,0-1.712-4.5,6.281,6.281,0,0,0-4.263-2.126V178a8.823,8.823,0,0,1,8.257,8.817,9.253,9.253,0,0,1-.213,1.98A10.132,10.132,0,0,1,98.051,190.733Z" transform="translate(-74.155 -171.132)" fill="#394676"/>
        </g>
      </g>
    </g>
    <text id="Amply_routes_your_email_in_real-time_to_maximize_placement_in_your_users_primary_inbox._An_auto_strategy_supports_failover_between_dedicated_and_shared_IP_addresses." data-name="Amply routes your email in real-time to maximize placement in your users’ 
primary inbox. An auto strategy supports failover between dedicated and 
shared IP addresses." transform="translate(266 328)" fill="#9eabc4" font-size="16" font-family="Inter"><tspan x="0" y="0">Amply's proprietary delivery system leverages real-time sending data to </tspan><tspan x="0" y="21">intelligently route emails directly to your users' primary inbox. This method </tspan><tspan x="0" y="42">supports automated failover between IP addresses and selects the best one to </tspan><tspan x="0" y="63">maximize deliverability.</tspan></text>
    <g id="Group_1477" data-name="Group 1477" transform="translate(38 -524)">
      <g id="Group_1462" data-name="Group 1462" transform="translate(0 674)">
        <g id="Rectangle_1090" data-name="Rectangle 1090" transform="translate(228 262)" fill="#fff" stroke="#e1ebfe" stroke-width="1">
          <rect width="225" height="36" rx="8" stroke="none"/>
          <rect x="0.5" y="0.5" width="224" height="35" rx="7.5" fill="none"/>
        </g>
        <text id="Auto" transform="translate(240 285.194)" fill="#0f1422" font-size="14" font-family="Inter"><tspan x="0" y="0">Auto</tspan></text>
      </g>
      <path id="expand_more_FILL0_wght400_GRAD200_opsz24" d="M223.881,401,220,397.119,221.119,396l2.761,2.761L226.642,396l1.119,1.119Z" transform="translate(213.239 555.5)" fill="#4836ff"/>
    </g>
    <g id="Medium_Primary_Button_:default" data-name="Medium Primary Button :default" transform="translate(500 412)">
      <g id="Group_202" data-name="Group 202">
        <rect id="Rectangle_488" data-name="Rectangle 488" width="102" height="36" rx="18" fill="url(#linear-gradient-4)"/>
      </g>
      <text id="Update" transform="translate(24 22)" fill="#fff" font-size="14" font-family="Inter"><tspan x="0" y="0">Update</tspan></text>
    </g>
    <g id="Group_5069" data-name="Group 5069">
      <path id="Path_15691" data-name="Path 15691" d="M.5,54.317V49.151A20.624,20.624,0,0,1,21.1,28.5h13.25a20.624,20.624,0,0,1,20.6,20.65V209.937a10.312,10.312,0,0,1-10.3,10.325H24.664" transform="translate(1272.192 117.386)" fill="none" stroke="#4835fb" stroke-width="1"/>
      <path id="Path_15692" data-name="Path 15692" d="M53.365,99.561V93.2a22.373,22.373,0,0,1,3.214-11.464l7.233-12.17A22.385,22.385,0,0,0,67.027,58.1V51.565" transform="translate(1205.665 118.614)" fill="none" stroke="#4835fb" stroke-width="1"/>
      <path id="Path_15693" data-name="Path 15693" d="M4,0A4,4,0,1,1,0,4,4,4,0,0,1,4,0Z" transform="translate(1261.193 227.089) rotate(-157.5)" fill="none" stroke="#4835fb" stroke-width="1.077"/>
    </g>
    <g id="Group_5070" data-name="Group 5070" transform="translate(932.332 419.73)">
      <path id="Path_15691-2" data-name="Path 15691" d="M54.946,194.445v5.166a20.624,20.624,0,0,1-20.6,20.65H21.1A20.624,20.624,0,0,1,.5,199.611V38.826A10.312,10.312,0,0,1,10.8,28.5H31.546" transform="translate(-0.5 -28.501)" fill="none" stroke="#4835fb" stroke-width="1"/>
      <path id="Path_15692-2" data-name="Path 15692" d="M67.027,51.565v6.361A22.373,22.373,0,0,1,63.812,69.39L56.579,81.56a22.385,22.385,0,0,0-3.214,11.465v6.536" transform="translate(1.081 67.907)" fill="none" stroke="#4835fb" stroke-width="1"/>
      <path id="Path_15693-2" data-name="Path 15693" d="M4,0A4,4,0,1,1,0,4,4,4,0,0,1,4,0Z" transform="translate(65.945 110.559) rotate(22.5)" fill="none" stroke="#4835fb" stroke-width="1.077"/>
    </g>
    <g id="Group_5071" data-name="Group 5071">
      <path id="Path_15695" data-name="Path 15695" d="M54.913,100.337l.033-21.186a20.624,20.624,0,0,0-20.6-20.65H21.1A20.624,20.624,0,0,0,.5,79.151V209.937a10.312,10.312,0,0,0,10.3,10.325H31.546" transform="translate(931.832 117.386)" fill="none" stroke="#4835fb" stroke-width="1"/>
      <path id="Path_15696" data-name="Path 15696" d="M4,8A4,4,0,1,0,0,4,4,4,0,0,0,4,8Z" transform="translate(981.553 219.698) rotate(-22.5)" fill="none" stroke="#4835fb" stroke-width="1.077"/>
    </g>
    <g id="Group_5072" data-name="Group 5072" transform="translate(1268.176 419.73)">
      <path id="Path_15695-2" data-name="Path 15695" d="M.533,168.425.5,179.611a20.491,20.491,0,0,0,20.329,20.65H33.906a20.491,20.491,0,0,0,20.33-20.65V38.826A10.246,10.246,0,0,0,44.071,28.5H23.849" transform="translate(4.727 -28.501)" fill="none" stroke="#4835fb" stroke-width="1"/>
      <path id="Path_15696-2" data-name="Path 15696" d="M4,8A4,4,0,1,0,0,4,4,4,0,0,0,4,8Z" transform="translate(10.452 137.95) rotate(157.5)" fill="none" stroke="#4835fb" stroke-width="1.077"/>
    </g>
    <path id="Polygon_1" data-name="Polygon 1" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(935.832 210) rotate(180)" fill="#4835fb"/>
    <path id="Polygon_20" data-name="Polygon 20" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(1330.639 210) rotate(180)" fill="#4835fb"/>
    <path id="Polygon_8" data-name="Polygon 8" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(955.832 179.387) rotate(-90)" fill="#4835fb"/>
    <path id="Polygon_26" data-name="Polygon 26" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(1299.792 594.991) rotate(-90)" fill="#4835fb"/>
    <path id="Polygon_11" data-name="Polygon 11" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(1295.832 341.148) rotate(-90)" fill="#4835fb"/>
    <path id="Polygon_22" data-name="Polygon 22" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(954.924 345.668) rotate(-90)" fill="#4835fb"/>
    <path id="Polygon_24" data-name="Polygon 24" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(954.924 423.27) rotate(-90)" fill="#4835fb"/>
    <path id="Polygon_13" data-name="Polygon 13" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(1295.832 418.75) rotate(-90)" fill="#4835fb"/>
    <path id="Polygon_12" data-name="Polygon 12" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(1308.32 338.668) rotate(90)" fill="#4835fb"/>
    <path id="Polygon_21" data-name="Polygon 21" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(967.413 334.147) rotate(90)" fill="#4835fb"/>
    <path id="Polygon_23" data-name="Polygon 23" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(967.413 411.75) rotate(90)" fill="#4835fb"/>
    <path id="Polygon_14" data-name="Polygon 14" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(1308.32 416.27) rotate(90)" fill="#4835fb"/>
    <path id="Polygon_10" data-name="Polygon 10" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(1302.292 142.387) rotate(90)" fill="#4835fb"/>
    <path id="Polygon_27" data-name="Polygon 27" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(963.332 607.991) rotate(90)" fill="#4835fb"/>
    <path id="Polygon_7" data-name="Polygon 7" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(983.25 205)" fill="#4835fb"/>
    <path id="Polygon_9" data-name="Polygon 9" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(1269.25 170.887)" fill="#4835fb"/>
    <path id="Polygon_2" data-name="Polygon 2" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(935.832 234) rotate(180)" fill="#4835fb"/>
    <path id="Polygon_19" data-name="Polygon 19" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(1330.639 234) rotate(180)" fill="#4835fb"/>
    <path id="Polygon_15" data-name="Polygon 15" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(1330.639 491) rotate(180)" fill="#4835fb"/>
    <path id="Polygon_25" data-name="Polygon 25" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(936.292 491) rotate(180)" fill="#4835fb"/>
    <path id="Polygon_1-2" data-name="Polygon 1" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(935.832 257) rotate(180)" fill="#4835fb"/>
    <path id="Polygon_18" data-name="Polygon 18" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(1330.639 257) rotate(180)" fill="#4835fb"/>
    <path id="Polygon_2-2" data-name="Polygon 2" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(935.832 281) rotate(180)" fill="#4835fb"/>
    <path id="Polygon_17" data-name="Polygon 17" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(1330.639 281) rotate(180)" fill="#4835fb"/>
    <g id="Group_5091" data-name="Group 5091">
      <path id="Polygon_16" data-name="Polygon 16" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(1330.639 467) rotate(180)" fill="#4835fb"/>
      <g id="Group_5088" data-name="Group 5088" transform="translate(0 289)">
        <path id="Polygon_5" data-name="Polygon 5" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(1330.639 225) rotate(180)" fill="#4835fb"/>
        <path id="Polygon_6" data-name="Polygon 6" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(1330.639 249) rotate(180)" fill="#4835fb"/>
      </g>
    </g>
    <g id="Group_5092" data-name="Group 5092" transform="translate(-394.807)">
      <path id="Polygon_16-2" data-name="Polygon 16" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(1330.639 467) rotate(180)" fill="#4835fb"/>
      <g id="Group_5088-2" data-name="Group 5088" transform="translate(0 289)">
        <path id="Polygon_5-2" data-name="Polygon 5" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(1330.639 225) rotate(180)" fill="#4835fb"/>
        <path id="Polygon_6-2" data-name="Polygon 6" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(1330.639 249) rotate(180)" fill="#4835fb"/>
      </g>
    </g>
    <g id="Group_5089" data-name="Group 5089">
      <g id="Group_5087" data-name="Group 5087" transform="translate(0 144.188)">
        <path id="Polygon_5-3" data-name="Polygon 5" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(1330.639 225) rotate(180)" fill="#4835fb"/>
        <path id="Polygon_6-3" data-name="Polygon 6" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(1330.639 249) rotate(180)" fill="#4835fb"/>
      </g>
    </g>
    <g id="Group_5090" data-name="Group 5090" transform="translate(-394.347)">
      <g id="Group_5087-2" data-name="Group 5087" transform="translate(0 144.188)">
        <path id="Polygon_5-4" data-name="Polygon 5" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(1330.639 225) rotate(180)" fill="#4835fb"/>
        <path id="Polygon_6-4" data-name="Polygon 6" d="M3.09.585a.5.5,0,0,1,.819,0l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787Z" transform="translate(1330.639 249) rotate(180)" fill="#4835fb"/>
      </g>
    </g>
    <g id="Group_5481" data-name="Group 5481" transform="translate(0 -63)">
      <g transform="matrix(1, 0, 0, 1, 0, 63)" filter="url(#Rectangle_2213-2)">
        <rect id="Rectangle_2213-5" data-name="Rectangle 2213" width="634" height="179" rx="15" transform="translate(884 252) rotate(180)" fill="#fff"/>
      </g>
      <g id="Group_5022" data-name="Group 5022" transform="translate(491 250)">
        <g id="Rectangle_2249" data-name="Rectangle 2249" fill="#fff" stroke="#e1ebfe" stroke-width="1">
          <rect width="275" height="55" rx="10" stroke="none"/>
          <rect x="0.5" y="0.5" width="274" height="54" rx="9.5" fill="none"/>
        </g>
        <text id="No_blocklists_detected" data-name="No blocklists detected" transform="translate(62 25)" fill="#131330" font-size="12" font-family="Inter" font-weight="700" letter-spacing="-0.015em"><tspan x="0" y="0">No blocklists detected</tspan></text>
        <text id="Blocklists" transform="translate(62 37)" fill="#9eabc4" font-size="10" font-family="Inter"><tspan x="0" y="0">Blocklists</tspan></text>
        <g id="Group_5479" data-name="Group 5479" transform="translate(-4)">
          <circle id="Ellipse_121" data-name="Ellipse 121" cx="18.5" cy="18.5" r="18.5" transform="translate(19 10)" fill="#e1ebfe"/>
          <path id="blocklists-2" data-name="blocklists" d="M10.1,13.7h1.8v1.8H10.1Zm0-7.2h1.8v5.4H10.1ZM10.991,2A9,9,0,1,0,20,11,9,9,0,0,0,10.991,2ZM11,18.2A7.2,7.2,0,1,1,18.2,11,7.2,7.2,0,0,1,11,18.2Z" transform="translate(27 17)" fill="#4835fb"/>
        </g>
      </g>
      <g id="Group_5023" data-name="Group 5023" transform="translate(266 185.346)">
        <g id="Rectangle_2249-2" data-name="Rectangle 2249" transform="translate(0 -0.346)" fill="#fff" stroke="#e1ebfe" stroke-width="1">
          <rect width="250" height="55" rx="10" stroke="none"/>
          <rect x="0.5" y="0.5" width="249" height="54" rx="9.5" fill="none"/>
        </g>
        <text id="sendamply.com" transform="translate(64 24.654)" fill="#131330" font-size="12" font-family="Inter" font-weight="700" letter-spacing="-0.015em"><tspan x="0" y="0">sendamply.com</tspan></text>
        <text id="Domain_Name" data-name="Domain Name" transform="translate(64 36.654)" fill="#9eabc4" font-size="10" font-family="Inter"><tspan x="0" y="0">Domain Name</tspan></text>
        <g id="Group_5477" data-name="Group 5477" transform="translate(-5)">
          <circle id="Ellipse_117" data-name="Ellipse 117" cx="19.5" cy="19.5" r="19.5" transform="translate(20 7.654)" fill="#e1ebfe"/>
          <path id="domain_name-2" data-name="domain_name" d="M134.347-861.091a2.6,2.6,0,0,1-1.909-.788,2.61,2.61,0,0,1-.786-1.914,2.576,2.576,0,0,1,.035-.386,1.967,1.967,0,0,1,.106-.41l-7.162-4.16a2.7,2.7,0,0,1-.875.65,2.444,2.444,0,0,1-1.064.248,2.6,2.6,0,0,1-1.909-.786,2.6,2.6,0,0,1-.786-1.909,2.6,2.6,0,0,1,.786-1.909,2.6,2.6,0,0,1,1.909-.786,2.614,2.614,0,0,1,1.04.213,2.853,2.853,0,0,1,.9.615l7.162-4.113a1.681,1.681,0,0,1-.106-.376,2.414,2.414,0,0,1-.035-.4,2.6,2.6,0,0,1,.786-1.909,2.6,2.6,0,0,1,1.909-.786,2.6,2.6,0,0,1,1.909.786,2.6,2.6,0,0,1,.786,1.909,2.6,2.6,0,0,1-.786,1.909,2.6,2.6,0,0,1-1.909.786,3.16,3.16,0,0,1-1.056-.177,2.022,2.022,0,0,1-.859-.579l-7.162,3.971a4.364,4.364,0,0,1,.083.437,3.045,3.045,0,0,1,.035.419,1.866,1.866,0,0,1-.035.354q-.035.183-.083.373l7.162,4.065a2.809,2.809,0,0,1,.827-.532,2.759,2.759,0,0,1,1.087-.2,2.6,2.6,0,0,1,1.909.786,2.6,2.6,0,0,1,.786,1.909,2.6,2.6,0,0,1-.786,1.909A2.6,2.6,0,0,1,134.347-861.091Zm0-14.938a1.233,1.233,0,0,0,.909-.367,1.237,1.237,0,0,0,.366-.91,1.233,1.233,0,0,0-.367-.909,1.237,1.237,0,0,0-.91-.366,1.233,1.233,0,0,0-.909.367,1.237,1.237,0,0,0-.366.91,1.233,1.233,0,0,0,.367.909A1.237,1.237,0,0,0,134.348-876.029Zm-11.653,6.76a1.233,1.233,0,0,0,.909-.367,1.237,1.237,0,0,0,.366-.91,1.233,1.233,0,0,0-.367-.909,1.237,1.237,0,0,0-.91-.366,1.233,1.233,0,0,0-.909.367,1.237,1.237,0,0,0-.366.91,1.233,1.233,0,0,0,.367.909A1.237,1.237,0,0,0,122.7-869.269Zm11.653,6.76a1.233,1.233,0,0,0,.909-.367,1.237,1.237,0,0,0,.366-.91,1.233,1.233,0,0,0-.367-.909,1.237,1.237,0,0,0-.91-.366,1.233,1.233,0,0,0-.909.367,1.237,1.237,0,0,0-.366.91,1.233,1.233,0,0,0,.367.909A1.237,1.237,0,0,0,134.348-862.509ZM134.347-877.305ZM122.695-870.545ZM134.347-863.785Z" transform="translate(-90.666 897.699)" fill="#4836ff"/>
        </g>
      </g>
      <g id="Group_5024" data-name="Group 5024" transform="translate(526 185.346)">
        <g id="Rectangle_2249-3" data-name="Rectangle 2249" transform="translate(0 -0.346)" fill="#fff" stroke="#e1ebfe" stroke-width="1">
          <rect width="215" height="55" rx="10" stroke="none"/>
          <rect x="0.5" y="0.5" width="214" height="54" rx="9.5" fill="none"/>
        </g>
        <text id="Enabled" transform="translate(64 24.654)" fill="#131330" font-size="12" font-family="Inter" font-weight="700" letter-spacing="-0.015em"><tspan x="0" y="0">Enabled</tspan></text>
        <text id="High_Availability" data-name="High Availability" transform="translate(64 36.654)" fill="#9eabc4" font-size="10" font-family="Inter"><tspan x="0" y="0">High Availability</tspan></text>
        <g id="Group_5480" data-name="Group 5480" transform="translate(-5)">
          <circle id="Ellipse_117-2" data-name="Ellipse 117" cx="19.5" cy="19.5" r="19.5" transform="translate(20 7.654)" fill="#e1ebfe"/>
          <g id="ha" transform="translate(30.185 17.337)">
            <path id="Path_15699" data-name="Path 15699" d="M0,0H18.632V18.632H0Z" fill="none"/>
            <path id="Path_15700" data-name="Path 15700" d="M9.316,5.553A4.272,4.272,0,0,1,13.5,8.992l.233,1.165,1.188.085a2.316,2.316,0,0,1-.171,4.627H4.658a3.1,3.1,0,0,1-.342-6.187L5.147,8.6l.388-.738A4.246,4.246,0,0,1,9.316,5.553M9.316,4A5.819,5.819,0,0,0,4.153,7.136a4.656,4.656,0,0,0,.5,9.285H14.751a3.871,3.871,0,0,0,.272-7.732A5.815,5.815,0,0,0,9.316,4Z" transform="translate(0 -0.895)" fill="#4836ff"/>
          </g>
        </g>
      </g>
      <g id="Group_5025" data-name="Group 5025" transform="translate(266 250.346)">
        <g id="Rectangle_2249-4" data-name="Rectangle 2249" transform="translate(0 -0.346)" fill="#fff" stroke="#e1ebfe" stroke-width="1">
          <rect width="215" height="55" rx="10" stroke="none"/>
          <rect x="0.5" y="0.5" width="214" height="54" rx="9.5" fill="none"/>
        </g>
        <text id="Healthy" transform="translate(63 24.654)" fill="#131330" font-size="12" font-family="Inter" font-weight="700" letter-spacing="-0.015em"><tspan x="0" y="0">Healthy</tspan></text>
        <text id="Status" transform="translate(63 36.654)" fill="#9eabc4" font-size="10" font-family="Inter"><tspan x="0" y="0">Status</tspan></text>
        <g id="Group_5478" data-name="Group 5478" transform="translate(-5)">
          <ellipse id="Ellipse_117-3" data-name="Ellipse 117" cx="19" cy="19.5" rx="19" ry="19.5" transform="translate(20 7.654)" fill="#e1ebfe"/>
          <g id="status-2" data-name="status" transform="translate(28.657 17.644)">
            <path id="Path_15697" data-name="Path 15697" d="M0,0H21.01V21.01H0Z" fill="none"/>
            <path id="Path_15698" data-name="Path 15698" d="M14.186,3A5.081,5.081,0,0,0,10.4,4.717,5.081,5.081,0,0,0,6.622,3,4.527,4.527,0,0,0,2,7.518C2,10.624,4.857,13.154,9.186,17L10.4,18.075l1.219-1.084c4.328-3.836,7.186-6.367,7.186-9.472A4.527,4.527,0,0,0,14.186,3Zm-3.7,12.775-.084.082-.084-.082c-4-3.541-6.639-5.882-6.639-8.256A2.841,2.841,0,0,1,6.622,4.643a3.288,3.288,0,0,1,3,1.939h1.572a3.267,3.267,0,0,1,2.992-1.939,2.841,2.841,0,0,1,2.941,2.875C17.127,9.893,14.489,12.234,10.488,15.775Z" transform="translate(0.101 0.152)" fill="#4836ff"/>
          </g>
        </g>
      </g>
      <text id="Verified_Domain" data-name="Verified Domain" transform="translate(266 170)" fill="#131330" font-size="19" font-family="Inter" font-weight="600" letter-spacing="-0.015em"><tspan x="0" y="0">Verified Domain</tspan></text>
      <g id="Rectangle_2213-6" data-name="Rectangle 2213" transform="translate(884 315) rotate(180)" fill="none" stroke="#d9e5fe" stroke-width="1">
        <rect width="634" height="179" rx="15" stroke="none"/>
        <rect x="0.5" y="0.5" width="633" height="178" rx="14.5" fill="none"/>
      </g>
    </g>
    <g id="Rectangle_2271" data-name="Rectangle 2271" transform="translate(884 470) rotate(180)" fill="none" stroke="#d9e5fe" stroke-width="1">
      <rect width="634" height="208" rx="15" stroke="none"/>
      <rect x="0.5" y="0.5" width="633" height="207" rx="14.5" fill="none"/>
    </g>
  </g>
</svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
