import React, { Component } from 'react';
import classnames from 'classnames';

import Orbs from 'components/Util/Misc/Orbs';

import Form from 'components/Util/Inputs/Form';
import InputL from 'components/Util/Inputs/InputL';
import TextareaL from 'components/Util/Inputs/TextareaL';
import Button from 'components/Util/Buttons/DarkBackground/LargeSecondarySquareButton';

import Store from 'models/Form';

import styles from 'styles/Marketing/Contact/Section1';

export default class Section1 extends Component {
  store = new Store;
  thankYou = React.createRef();

  onSuccess = () => {
    const transitionDuration = styles.fadeTransitionDuration;
    const form = this.store.getRef('form').current;
    const thankYou = this.thankYou.current;

    form.classList.add(styles.FadeOut);

    setTimeout(() => {
      form.classList.add('d-none');
      thankYou.classList.remove('d-none');
      thankYou.classList.add(styles.FadeIn);
    }, parseFloat(transitionDuration) * 1000);
  }

  render() {
    const orbs = [
      // PURPLE ORBS
      // bottom left
      {
        width: '500px',
        height: '500px',
        bottom: '-360px',
        left: 'calc(50% - 590px)',
        background: styles.colorPrimaryPurple,
        animationDuration: '8s',
      },
      // top left
      {
        width: '500px',
        height: '500px',
        top: '-425px',
        left: 'calc(50% - 900px)',
        background: styles.colorPrimaryPurple,
        animationDuration: '8s',
        animationDelay: '-3.25s',
      },

    ];

    return (
      <Orbs orbs={orbs} blurEffect={false}>
        <div className={classnames(styles.Container, 'mx-auto', 'py-5')}>
          <div className={classnames(styles.MaxWidth, 'mx-auto', 'px-lg-4', 'px-2')}>
            <div className='row'>
              <div className='col-lg-6'>
                <h1 className={classnames(styles.h1, styles.ColorPrimaryWhite)}>Get in touch</h1>
                <p className={classnames(styles.h5, styles.ColorPrimaryWhite)} style={{maxWidth: '400px'}}>Have a question? We're here for you. Contact the Amply team and let us know how we can help.</p>
                <p className={classnames(styles.p2, styles.ColorPrimaryWhite)} style={{maxWidth: '400px'}}>Also, check out our <a href='https://docs.sendamply.com' className={classnames(styles.ColorSecondaryOrange)}>Docs</a> for more information.</p>
              </div>

              <div className='col-lg-6'>
                <div style={{ maxWidth: '426px', height: '512px' }}>
                  <Form name='form' method='post' action='/contact' onSuccess={this.onSuccess} store={this.store}>

                    <div className='row'>
                      <div className={classnames(styles.FirstName, 'col-lg-6')}>
                        <InputL className='mb-2 w-100' name='first_name' placeholder='First Name*' store={this.store} />
                      </div>

                      <div className={classnames(styles.LastName, 'col-lg-6')}>
                        <InputL className='mb-2 w-100' name='last_name' placeholder='Last Name*' store={this.store} />
                      </div>
                    </div>

                    <InputL className='mb-2 w-100' name='email' placeholder='Email*' store={this.store} />
                    <InputL className='mb-2 w-100' name='organization' placeholder='Organization' store={this.store} />
                    <TextareaL className='w-100 mb-2' name='message' placeholder='Message*' store={this.store} style={{ height: '190px' }}/>
                    <Button>Submit</Button>
                  </Form>

                  <p className={classnames(styles.h5, styles.ColorPrimaryWhite, 'd-none', 'opacity-0')} ref={this.thankYou}>Thank you for your interest in Amply. <br/>We will be in touch soon!</p>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </Orbs>
    );
  }
}
