import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

export default class MonitorInfrastructure extends Component {
  render() {
    const src = `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1400" height="780" viewBox="0 0 1400 780">
  <defs>
    <linearGradient id="linear-gradient" x1="0.5" y1="1" x2="0.5" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#fcfcfc"/>
      <stop offset="1" stop-color="#f4f9ff"/>
    </linearGradient>
    <linearGradient id="linear-gradient-2" x1="1" y1="1" x2="0" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#6834ff"/>
      <stop offset="0" stop-color="#6633fd"/>
      <stop offset="1" stop-color="#4836ff"/>
    </linearGradient>
    <clipPath id="clip-path">
      <rect width="220" height="760" fill="none"/>
    </clipPath>
    <clipPath id="clip-path-2">
      <rect id="Rectangle_2198" data-name="Rectangle 2198" width="93.26" height="30.598" fill="#fff"/>
    </clipPath>
    <filter id="Rectangle_2213" x="250" y="553" width="1135" height="212" filterUnits="userSpaceOnUse">
      <feOffset input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="5" result="blur"/>
      <feFlood flood-color="#e1ebfe"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="Rectangle_2213-2" x="250" y="138" width="1135" height="230" filterUnits="userSpaceOnUse">
      <feOffset input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="5" result="blur-2"/>
      <feFlood flood-color="#e1ebfe"/>
      <feComposite operator="in" in2="blur-2"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="Rectangle_2213-3" x="250" y="358" width="391" height="205" filterUnits="userSpaceOnUse">
      <feOffset input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="5" result="blur-3"/>
      <feFlood flood-color="#e1ebfe"/>
      <feComposite operator="in" in2="blur-3"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="Rectangle_2213-4" x="622" y="358" width="391" height="205" filterUnits="userSpaceOnUse">
      <feOffset input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="5" result="blur-4"/>
      <feFlood flood-color="#e1ebfe"/>
      <feComposite operator="in" in2="blur-4"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="Rectangle_2213-5" x="994" y="358" width="391" height="205" filterUnits="userSpaceOnUse">
      <feOffset input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="5" result="blur-5"/>
      <feFlood flood-color="#e1ebfe"/>
      <feComposite operator="in" in2="blur-5"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <linearGradient id="linear-gradient-3" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#25de42"/>
      <stop offset="1" stop-color="#00fa4f"/>
    </linearGradient>
    <linearGradient id="linear-gradient-4" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#fc2832"/>
      <stop offset="1" stop-color="#ff4058"/>
    </linearGradient>
    <clipPath id="clip-path-3">
      <rect id="Rectangle_2240" data-name="Rectangle 2240" width="134" height="134" transform="translate(0 -0.004)" fill="none"/>
    </clipPath>
    <linearGradient id="linear-gradient-5" x1="-1.015" y1="2.059" x2="-1.003" y2="2.059" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#ff4058"/>
      <stop offset="1" stop-color="#ff2428"/>
    </linearGradient>
    <radialGradient id="radial-gradient" cx="0.5" cy="0.5" r="0.558" gradientTransform="translate(-0.027 0.028) rotate(-3.149)" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#25de42"/>
      <stop offset="0.488" stop-color="#2afa82"/>
      <stop offset="1" stop-color="#29f954"/>
    </radialGradient>
    <linearGradient id="linear-gradient-6" x1="0.921" y1="0.084" x2="0.328" y2="0.853" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#0b151e"/>
      <stop offset="1" stop-color="#303a57"/>
    </linearGradient>
    <clipPath id="clip-path-4">
      <rect id="Rectangle_2240-2" data-name="Rectangle 2240" width="134" height="134" transform="translate(0 0)" fill="none"/>
    </clipPath>
    <radialGradient id="radial-gradient-2" cx="0.5" cy="0.5" r="0.546" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#25de42"/>
      <stop offset="0.695" stop-color="#2afa8d"/>
      <stop offset="1" stop-color="#29f954"/>
    </radialGradient>
    <linearGradient id="linear-gradient-17" x1="0.5" y1="0.992" x2="0.5" y2="0.016" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#2a22f0"/>
      <stop offset="1" stop-color="#7c1ffc"/>
    </linearGradient>
    <linearGradient id="linear-gradient-18" y1="0.99" y2="-0.185" xlink:href="#linear-gradient-17"/>
    <linearGradient id="linear-gradient-21" y1="0.993" y2="0.139" xlink:href="#linear-gradient-17"/>
    <linearGradient id="linear-gradient-22" y1="0.989" y2="-0.333" xlink:href="#linear-gradient-17"/>
    <linearGradient id="linear-gradient-23" y1="0.993" y2="0.082" xlink:href="#linear-gradient-17"/>
    <linearGradient id="linear-gradient-36" y1="0.973" y2="-2.382" xlink:href="#linear-gradient-17"/>
    <clipPath id="clip-path-6">
      <rect id="Rectangle_2278" data-name="Rectangle 2278" width="1105" height="182" rx="15" transform="translate(1370 750) rotate(180)" fill="#fff"/>
    </clipPath>
    <clipPath id="clip-Homepage_02_Monitor_Infrastructure_Health">
      <rect width="1400" height="780"/>
    </clipPath>
  </defs>
  <g id="Homepage_02_Monitor_Infrastructure_Health" data-name="Homepage_02 Monitor Infrastructure Health" clip-path="url(#clip-Homepage_02_Monitor_Infrastructure_Health)">
    <rect width="1400" height="780" fill="#fff"/>
    <rect id="Rectangle_2248" data-name="Rectangle 2248" width="1380" height="746" rx="15" transform="translate(10 24)" fill="url(#linear-gradient)"/>
    <rect id="Rectangle_667" data-name="Rectangle 667" width="1373" height="86" transform="translate(1390 136) rotate(180)" fill="#192038"/>
    <g id="Medium_Secondary_Icon_:default" data-name="Medium Secondary Icon :default" transform="translate(276 73)">
      <g id="Group_202" data-name="Group 202">
        <g id="Rectangle_488" data-name="Rectangle 488" fill="none" stroke="#fff" stroke-width="1">
          <rect width="93" height="36" rx="18" stroke="none"/>
          <rect x="0.5" y="0.5" width="92" height="35" rx="17.5" fill="none"/>
        </g>
      </g>
      <text id="DevOps" transform="translate(46.5 22.611)" fill="#fff" font-size="14" font-family="Inter"><tspan x="-26.141" y="0">Delivery</tspan></text>
    </g>
    <g id="Group_5074" data-name="Group 5074" transform="translate(-17 13)">
      <g id="Group_202-2" data-name="Group 202" transform="translate(396 60)">
        <g id="Rectangle_488-2" data-name="Rectangle 488" fill="none" stroke="#394676" stroke-width="1">
          <rect width="93" height="36" rx="18" stroke="none"/>
          <rect x="0.5" y="0.5" width="92" height="35" rx="17.5" fill="none"/>
        </g>
      </g>
      <text id="Product" transform="translate(443 82.611)" fill="#394676" font-size="14" font-family="Inter"><tspan x="-17.972" y="0">Users</tspan></text>
    </g>
    <g id="Group_5076" data-name="Group 5076" transform="translate(20 13)">
      <g id="Group_4877" data-name="Group 4877" transform="translate(4778.848 8152)">
        <g id="Group_4876" data-name="Group 4876" transform="translate(-3557 -8092)">
          <g id="Rectangle_1090-2" data-name="Rectangle 1090" transform="translate(0.151)" fill="none" stroke="#3c444f" stroke-width="1">
            <rect width="128" height="36" rx="8" stroke="none"/>
            <rect x="0.5" y="0.5" width="127" height="35" rx="7.5" fill="none"/>
          </g>
          <text id="This_Month-2" data-name="This Month" transform="translate(41.151 23)" fill="none" font-size="12" font-family="Inter"><tspan x="0" y="0">This Month</tspan></text>
        </g>
        <path id="calendar_today_FILL0_wght400_GRAD0_opsz24" d="M121.3,189a1.3,1.3,0,0,1-1.3-1.3v-9.1a1.3,1.3,0,0,1,1.3-1.3h.65V176h1.3v1.3h5.2V176h1.3v1.3h.65a1.3,1.3,0,0,1,1.3,1.3v9.1a1.3,1.3,0,0,1-1.3,1.3Zm0-1.3h9.1v-6.5h-9.1Zm0-7.8h9.1v-1.3h-9.1Zm0,0v0Z" transform="translate(-3663 -8256.5)" fill="#e1ebff"/>
      </g>
      <text id="This_Month-3" data-name="This Month" transform="translate(1260 83.5)" fill="#fff" font-size="14" font-family="Inter"><tspan x="0" y="0">This Month</tspan></text>
    </g>
    <g id="Repeat_Grid_3" data-name="Repeat Grid 3" transform="translate(10 10)" clip-path="url(#clip-path)">
      <g transform="translate(-10 -10)">
        <rect id="Rectangle_2247" data-name="Rectangle 2247" width="220" height="760" rx="15" transform="translate(10 10)" fill="#0b151e"/>
      </g>
    </g>
    <path id="Rectangle_2251" data-name="Rectangle 2251" d="M15,0H1365a15,15,0,0,1,15,15V40a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V15A15,15,0,0,1,15,0Z" transform="translate(10 10)" fill="#192038"/>
    <g id="Amply_Logo_White" data-name="Amply Logo White" transform="translate(71 78.402)" clip-path="url(#clip-path-2)">
      <path id="Path_15661" data-name="Path 15661" d="M79.5,19.4a6.112,6.112,0,0,0-6.2,2.622,6.119,6.119,0,0,0-9.539-.8L63.747,19.7H59.461V37.074h4.65V25.909a2.762,2.762,0,1,1,5.523-.064V37.074h4.651V25.909a2.761,2.761,0,1,1,5.522-.064V37.074h4.651V25.412A6.079,6.079,0,0,0,79.5,19.4" transform="translate(-40.089 -13.087)" fill="#fff"/>
      <rect id="Rectangle_2197" data-name="Rectangle 2197" width="4.651" height="23.987" transform="translate(68.783)" fill="#fff"/>
      <path id="Path_15662" data-name="Path 15662" d="M155.633,19.3a8.842,8.842,0,0,0-5.51,1.924V19.7h-4.286V43.684h4.651V35.815a8.831,8.831,0,0,0,5.146,1.65,9.082,9.082,0,0,0,0-18.162m-.419,14.352a5.021,5.021,0,0,1-4.727-5.271,4.755,4.755,0,1,1,9.454,0,5.021,5.021,0,0,1-4.727,5.271" transform="translate(-98.324 -13.087)" fill="#fff"/>
      <path id="Path_15663" data-name="Path 15663" d="M8.784,19.306a14.285,14.285,0,0,0-7.537,2.13l1.438,3.747a12.193,12.193,0,0,1,4.924-1.792c2.522-.3,3.97.648,3.97,1.645-2.6.864-10.278,2-11.369,5.871-1.1,3.906,2.373,6.168,4.824,6.168h6.018l-2.67-1.524,2.174-5.745a.036.036,0,0,0-.062-.035L6.547,34.5h0L3.191,32.589l8.547-4.879v9.365h4.49V26.322c0-5.967-3.776-7.016-7.445-7.016" transform="translate(0 -13.088)" fill="#fff"/>
      <path id="Path_15664" data-name="Path 15664" d="M243.844,20.525l-4.085,11.637-4.085-11.637H230.71l6.567,17.364-2.505,6.623h4.964l9.072-23.987Z" transform="translate(-155.547 -13.914)" fill="#fff"/>
    </g>
    <g id="Group_5015" data-name="Group 5015" transform="translate(0 97.729)">
      <g id="Group_5018" data-name="Group 5018" transform="translate(10 220)">
        <g id="Group_4854" data-name="Group 4854" transform="translate(0 0)">
          <text id="Mail_Settings" data-name="Mail Settings" transform="translate(65 33.271)" fill="#394676" font-size="18" font-family="FKDisplay-Regular, FK Display, FKDisplayAlt" letter-spacing="-0.015em"><tspan x="0" y="0">Mail Settings</tspan></text>
          <g id="Group_4676" data-name="Group 4676" transform="translate(0 0.271)">
            <rect id="Rectangle_1827" data-name="Rectangle 1827" width="218" height="55" transform="translate(0 0)" fill="none"/>
          </g>
          <g id="Group_5017" data-name="Group 5017" transform="translate(22 12.271)">
            <g id="Medium_Icon_Button_:default" data-name="Medium Icon Button :default" transform="translate(0 0)">
              <rect id="base" width="32" height="32" rx="16" fill="#192038"/>
            </g>
            <path id="settings_FILL0_wght400_GRAD0_opsz48" d="M86.916,193.963l-.449-2.829a6.015,6.015,0,0,1-.9-.427,6.172,6.172,0,0,1-.831-.561l-2.65,1.212L80,187.676l2.425-1.774a2.669,2.669,0,0,1-.056-.46q-.011-.258-.011-.46t.011-.46a2.669,2.669,0,0,1,.056-.46L80,182.287l2.088-3.682,2.65,1.213a6.174,6.174,0,0,1,.831-.561,4.646,4.646,0,0,1,.9-.4L86.916,176h4.132l.449,2.829a7.279,7.279,0,0,1,.909.415,3.866,3.866,0,0,1,.82.573l2.65-1.213,2.088,3.682-2.425,1.729a3.164,3.164,0,0,1,.056.483q.011.258.011.483t-.011.471a3.126,3.126,0,0,1-.056.472l2.425,1.751-2.088,3.682-2.65-1.212a7.453,7.453,0,0,1-.82.573,3.913,3.913,0,0,1-.909.415l-.449,2.829Zm2.066-6.063a2.913,2.913,0,1,0-2.066-.853A2.814,2.814,0,0,0,88.982,187.9Zm0-1.347a1.572,1.572,0,1,1,1.111-.46A1.514,1.514,0,0,1,88.982,186.553ZM88.982,184.982Zm-.988,7.634h1.976l.314-2.515a5.3,5.3,0,0,0,1.4-.561,5.755,5.755,0,0,0,1.2-.921l2.38,1.033.9-1.617-2.111-1.549q.09-.382.146-.752a5.014,5.014,0,0,0,.056-.752,6.246,6.246,0,0,0-.045-.752,4.648,4.648,0,0,0-.157-.752l2.111-1.549-.9-1.617-2.38,1.033a5.005,5.005,0,0,0-1.168-.977,3.787,3.787,0,0,0-1.437-.505l-.314-2.515H87.994l-.314,2.515a4.758,4.758,0,0,0-1.426.539,4.953,4.953,0,0,0-1.179.943l-2.38-1.033-.9,1.617,2.111,1.549q-.09.382-.146.752a5.068,5.068,0,0,0,0,1.5q.056.371.146.752L81.8,188.035l.9,1.617,2.38-1.033a5.754,5.754,0,0,0,1.2.921,5.3,5.3,0,0,0,1.4.561Z" transform="translate(-72.981 -168.981)" fill="#394676"/>
          </g>
        </g>
      </g>
    </g>
    <line id="Line_1351" data-name="Line 1351" x1="1380" transform="translate(10 50)" fill="none" stroke="#2f385a" stroke-width="1"/>
    <line id="Line_1353" data-name="Line 1353" x1="220" transform="translate(10 136)" fill="none" stroke="#2f385a" stroke-width="1"/>
    <line id="Line_1352" data-name="Line 1352" x1="220" transform="translate(10 619)" fill="none" stroke="#2f385a" stroke-width="1"/>
    <g id="Group_5036" data-name="Group 5036" transform="translate(2 1.537)">
      <circle id="Ellipse_118" data-name="Ellipse 118" cx="6" cy="6" r="6" transform="translate(22 22.463)" fill="#2bfaa2"/>
      <ellipse id="Ellipse_119" data-name="Ellipse 119" cx="6.5" cy="6" rx="6.5" ry="6" transform="translate(39 22.463)" fill="#fd9b51"/>
      <ellipse id="Ellipse_120" data-name="Ellipse 120" cx="6.5" cy="6" rx="6.5" ry="6" transform="translate(56 22.463)" fill="#4836ff"/>
    </g>
    <g id="Group_5037" data-name="Group 5037">
      <g id="Group_4846" data-name="Group 4846" transform="translate(10 153)">
        <g id="Group_4671" data-name="Group 4671" transform="translate(20.883 10.961)">
          <g id="Medium_Icon_Button_:default-2" data-name="Medium Icon Button :default" transform="translate(0.117 0.039)">
            <rect id="base-2" data-name="base" width="32" height="32" rx="16" fill="#4835fb"/>
          </g>
          <path id="home_FILL0_wght400_GRAD0_opsz48" d="M161.484,232.327H165.2v-6.185h5.442v6.185h3.711v-9.648l-6.432-4.824-6.432,4.824ZM160,233.812V221.937L167.916,216l7.916,5.937v11.874h-6.679v-6.185h-2.474v6.185ZM167.916,225.079Z" transform="translate(-152.16 -209.149)" fill="#fff"/>
        </g>
        <text id="Mail_Settings-2" data-name="Mail Settings" transform="translate(65 34)" fill="#fff" font-size="19" font-family="FKDisplay-Regular, FK Display, FKDisplayAlt"><tspan x="0" y="0">Dashboard</tspan></text>
        <g id="Group_4676-2" data-name="Group 4676">
          <rect id="Rectangle_1827-2" data-name="Rectangle 1827" width="220" height="55" fill="none"/>
        </g>
      </g>
      <g id="Group_4860" data-name="Group 4860" transform="translate(10 263.057)">
        <g id="Group_4671-2" data-name="Group 4671" transform="translate(22.073 10.366)">
          <g id="Medium_Icon_Button_:default-3" data-name="Medium Icon Button :default" transform="translate(-0.073 -0.423)">
            <rect id="base-3" data-name="base" width="32" height="32" rx="16" fill="#192038"/>
          </g>
          <path id="auto_graph_FILL0_wght400_GRAD0_opsz48" d="M47.009,181.607l-.894-1.909-1.909-.894,1.909-.894L47.009,176l.894,1.909,1.909.894L47.9,179.7Zm6.526,2.924-.749-1.6-1.6-.749,1.6-.749.749-1.6.749,1.6,1.6.749-1.6.749Zm-11.19,1.933-.749-1.6-1.6-.749,1.6-.749.749-1.6.749,1.6,1.6.749-1.6.749Zm.7,7.42-1.112-1.112,7.251-7.251,3.867,3.891,7.2-8.072,1.015.991-8.217,9.257-3.867-3.843Z" transform="translate(-34.868 -168.618)" fill="#394676"/>
        </g>
        <text id="Monitors" transform="translate(65 31.943)" fill="#394676" font-size="17" font-family="FKDisplay-Regular, FK Display, FKDisplayAlt" letter-spacing="-0.015em"><tspan x="0" y="0">Monitors</tspan></text>
        <g id="Group_4676-3" data-name="Group 4676" transform="translate(0 0.301)">
          <rect id="Rectangle_1827-3" data-name="Rectangle 1827" width="220" height="55" transform="translate(0 -0.357)" fill="none"/>
        </g>
      </g>
      <g id="Group_4862" data-name="Group 4862" transform="translate(10 208.042)">
        <text id="Analytics" transform="translate(65 34.958)" fill="#394676" font-size="19" font-family="FKDisplay-Regular, FK Display, FKDisplayAlt" letter-spacing="-0.015em"><tspan x="0" y="0">Analytics</tspan></text>
        <rect id="Rectangle_1827-4" data-name="Rectangle 1827" width="220" height="55" transform="translate(0 -0.042)" fill="none"/>
        <g id="Group_4861" data-name="Group 4861" transform="translate(21 12.378)">
          <path id="base-4" data-name="base" d="M15.8,0A15.8,15.8,0,1,1,0,15.8,15.8,15.8,0,0,1,15.8,0Z" fill="#192038"/>
          <path id="data_usage_FILL0_wght400_GRAD0_opsz48" d="M89.951,195.857a8.661,8.661,0,0,1-3.468-.7,9.175,9.175,0,0,1-2.853-1.913A8.945,8.945,0,0,1,81.7,190.4a8.911,8.911,0,0,1,7.6-12.374V180.3a6.649,6.649,0,0,0,.649,13.27,6.63,6.63,0,0,0,3.043-.727,7.06,7.06,0,0,0,2.439-2.048l1.969,1.141a9.084,9.084,0,0,1-3.256,2.887A8.884,8.884,0,0,1,89.951,195.857Zm8.1-5.124-1.969-1.1a8.191,8.191,0,0,0,.4-1.376,7,7,0,0,0,.134-1.354,6.5,6.5,0,0,0-1.712-4.5,6.281,6.281,0,0,0-4.263-2.126V178a8.823,8.823,0,0,1,8.257,8.817,9.253,9.253,0,0,1-.213,1.98A10.132,10.132,0,0,1,98.051,190.733Z" transform="translate(-74.155 -171.132)" fill="#394676"/>
        </g>
      </g>
      <path id="Rectangle_2263" data-name="Rectangle 2263" d="M0,0H0A3,3,0,0,1,3,3V52a3,3,0,0,1-3,3H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(10 153)" fill="#fd9b51"/>
    </g>
    <line id="Line_1354" data-name="Line 1354" y1="86" transform="translate(230 50)" fill="none" stroke="#2f385a" stroke-width="1"/>
    <g id="Group_5085" data-name="Group 5085" transform="translate(-14189 8238.938)">
      <g id="Group_5029" data-name="Group 5029" transform="translate(14454 -7670.938)">
        <g transform="matrix(1, 0, 0, 1, -265, -568)" filter="url(#Rectangle_2213)">
          <rect id="Rectangle_2213-6" data-name="Rectangle 2213" width="1105" height="182" rx="15" transform="translate(1370 750) rotate(180)" fill="#fff"/>
        </g>
        <g id="Group_5027" data-name="Group 5027" transform="translate(16 16)">
          <text id="Bounce_and_Spam_Rate" data-name="Bounce and Spam Rate" transform="translate(0 18)" fill="#131330" font-size="19" font-family="Inter" font-weight="600" letter-spacing="-0.015em"><tspan x="0" y="0">Bounce and Spam Rate</tspan></text>
        </g>
      </g>
    </g>
    <g id="Group_5109" data-name="Group 5109" transform="translate(-14189 7823.938)">
      <g id="Group_5029-2" data-name="Group 5029" transform="translate(14454 -7670.938)">
        <g transform="matrix(1, 0, 0, 1, -265, -153)" filter="url(#Rectangle_2213-2)">
          <rect id="Rectangle_2213-7" data-name="Rectangle 2213" width="1105" height="200" rx="15" transform="translate(1370 353) rotate(180)" fill="#fff"/>
        </g>
        <g id="Group_5027-2" data-name="Group 5027" transform="translate(16 16)">
          <text id="Daily_Events" data-name="Daily Events" transform="translate(0 18)" fill="#131330" font-size="19" font-family="Inter" font-weight="600" letter-spacing="-0.015em"><tspan x="0" y="0">Daily Events</tspan></text>
        </g>
      </g>
    </g>
    <g id="Group_5108" data-name="Group 5108" transform="translate(0 -4)">
      <g id="Group_5078" data-name="Group 5078" transform="translate(265 362)">
        <g transform="matrix(1, 0, 0, 1, -265, -358)" filter="url(#Rectangle_2213-3)">
          <rect id="Rectangle_2213-8" data-name="Rectangle 2213" width="361" height="175" rx="15" transform="translate(626 548) rotate(180)" fill="#fff"/>
        </g>
      </g>
      <g transform="matrix(1, 0, 0, 1, 0, 4)" filter="url(#Rectangle_2213-4)">
        <rect id="Rectangle_2213-9" data-name="Rectangle 2213" width="361" height="175" rx="15" transform="translate(998 548) rotate(180)" fill="#fff"/>
      </g>
      <g transform="matrix(1, 0, 0, 1, 0, 4)" filter="url(#Rectangle_2213-5)">
        <rect id="Rectangle_2213-10" data-name="Rectangle 2213" width="361" height="175" rx="15" transform="translate(1370 548) rotate(180)" fill="#fff"/>
      </g>
      <g id="Group_5105" data-name="Group 5105" transform="translate(0 14)">
        <text id="Domain_Monitors" data-name="Domain Monitors" transform="translate(282 395)" fill="#131330" font-size="19" font-family="Inter" font-weight="600" letter-spacing="-0.015em"><tspan x="0" y="0">Domain Monitors</tspan></text>
        <g id="Group_5104" data-name="Group 5104" transform="translate(11)">
          <circle id="Ellipse_113" data-name="Ellipse 113" cx="12" cy="12" r="12" transform="translate(284 431)" fill="url(#linear-gradient-3)"/>
          <text id="_1" data-name="1" transform="translate(296 447)" fill="#0b151e" font-size="12" font-family="SpaceMono-Bold, Space Mono" font-weight="700" letter-spacing="-0.01em"><tspan x="-3.672" y="0">1</tspan></text>
          <path id="Path_15683" data-name="Path 15683" d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z" transform="translate(284 471.523)" fill="url(#linear-gradient-4)"/>
          <text id="_0" data-name="0" transform="translate(296 488)" fill="#fff" font-size="12" font-family="SpaceMono-Bold, Space Mono" font-weight="700"><tspan x="-3.672" y="0">0</tspan></text>
          <rect id="Rectangle_2265" data-name="Rectangle 2265" width="118" height="23" rx="11.5" transform="translate(314 472)" fill="#e1ebff"/>
          <g id="Group_5100" data-name="Group 5100" transform="translate(-20)">
            <g id="Group_5084" data-name="Group 5084" transform="translate(472 390.004)">
              <g id="Group_4995" data-name="Group 4995">
                <g id="Group_5002" data-name="Group 5002">
                  <g id="Group_5001" data-name="Group 5001" clip-path="url(#clip-path-3)">
                    <path id="Path_15681" data-name="Path 15681" d="M85,.5Z" transform="translate(-18.002 -0.106)" fill="url(#linear-gradient-5)"/>
                    <path id="Path_15682" data-name="Path 15682" d="M75.469,40A35.47,35.47,0,1,1,40,75.471,35.469,35.469,0,0,1,75.469,40" transform="translate(-8.471 -8.472)" fill="#fff"/>
                  </g>
                </g>
                <circle id="Ellipse_123" data-name="Ellipse 123" cx="67" cy="67" r="67" transform="translate(0 -0.004)" fill="url(#radial-gradient)"/>
              </g>
              <circle id="Ellipse_122" data-name="Ellipse 122" cx="36" cy="36" r="36" transform="translate(31 30.996)" fill="url(#linear-gradient-6)"/>
            </g>
            <text id="_100_" data-name="100%" transform="translate(539 464)" fill="#fff" font-size="22" font-family="FKDisplay-Regular, FK Display, FKDisplayAlt" font-weight="700" letter-spacing="-0.03em"><tspan x="-26.576" y="0">100%</tspan></text>
          </g>
          <rect id="Rectangle_2266" data-name="Rectangle 2266" width="118" height="23" rx="11.5" transform="translate(314 434)" fill="#e1ebff"/>
        </g>
      </g>
      <g id="Group_5106" data-name="Group 5106" transform="translate(0 14)">
        <text id="IP_Address_Monitors" data-name="IP Address Monitors" transform="translate(654 395)" fill="#131330" font-size="19" font-family="Inter" font-weight="600" letter-spacing="-0.015em"><tspan x="0" y="0">IP Address Monitors</tspan></text>
        <g id="Group_5103" data-name="Group 5103" transform="translate(11)">
          <circle id="Ellipse_113-2" data-name="Ellipse 113" cx="12" cy="12" r="12" transform="translate(656 431)" fill="url(#linear-gradient-3)"/>
          <text id="_11" data-name="11" transform="translate(668 447)" fill="#0b151e" font-size="12" font-family="SpaceMono-Bold, Space Mono" font-weight="700" letter-spacing="-0.01em"><tspan x="-7.284" y="0">11</tspan></text>
          <path id="Path_15683-2" data-name="Path 15683" d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z" transform="translate(656 471.519)" fill="url(#linear-gradient-4)"/>
          <text id="_1-2" data-name="1" transform="translate(668 488)" fill="#fff" font-size="12" font-family="SpaceMono-Bold, Space Mono" font-weight="700"><tspan x="-3.672" y="0">1</tspan></text>
          <g id="Group_5101" data-name="Group 5101" transform="translate(-20)">
            <g id="Group_4995-2" data-name="Group 4995" transform="translate(844 390)">
              <g id="Group_5002-2" data-name="Group 5002">
                <g id="Group_5001-2" data-name="Group 5001" clip-path="url(#clip-path-4)">
                  <path id="Path_15681-2" data-name="Path 15681" d="M85,.5Z" transform="translate(-18.002 -0.106)" fill="url(#linear-gradient-5)"/>
                  <path id="Path_15682-2" data-name="Path 15682" d="M75.469,40A35.47,35.47,0,1,1,40,75.471,35.469,35.469,0,0,1,75.469,40" transform="translate(-8.471 -8.472)" fill="#fff"/>
                </g>
              </g>
              <circle id="Ellipse_123-2" data-name="Ellipse 123" cx="67" cy="67" r="67" transform="translate(0 0)" fill="url(#radial-gradient-2)"/>
            </g>
            <path id="Subtraction_6" data-name="Subtraction 6" d="M4.947,66.49v0h0A11.788,11.788,0,0,0,.108,65.455H0V11.13A67.1,67.1,0,0,1,17.617,13.5L4.947,66.488Z" transform="translate(911.404 378.863)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" fill="url(#linear-gradient-5)"/>
            <circle id="Ellipse_154" data-name="Ellipse 154" cx="36" cy="36" r="36" transform="translate(875 421)" fill="url(#linear-gradient-6)"/>
            <text id="_92_" data-name="92%" transform="translate(911 464)" fill="#fff" font-size="22" font-family="FKDisplay-Regular, FK Display, FKDisplayAlt" font-weight="600" letter-spacing="-0.03em"><tspan x="-21.01" y="0">92%</tspan></text>
          </g>
          <rect id="Rectangle_2267" data-name="Rectangle 2267" width="118" height="23" rx="11.5" transform="translate(686 472)" fill="#e1ebff"/>
          <rect id="Rectangle_2268" data-name="Rectangle 2268" width="118" height="23" rx="11.5" transform="translate(686 434)" fill="#e1ebff"/>
        </g>
      </g>
      <g id="Group_5107" data-name="Group 5107" transform="translate(0 14)">
        <text id="IP_Pool_Monitors" data-name="IP Pool Monitors" transform="translate(1026 395)" fill="#131330" font-size="19" font-family="Inter" font-weight="600" letter-spacing="-0.015em"><tspan x="0" y="0">IP Pool Monitors</tspan></text>
        <g id="Group_5102" data-name="Group 5102" transform="translate(11)">
          <circle id="Ellipse_113-3" data-name="Ellipse 113" cx="12" cy="12" r="12" transform="translate(1028 431)" fill="url(#linear-gradient-3)"/>
          <text id="_11-2" data-name="11" transform="translate(1040 447)" fill="#0b151e" font-size="12" font-family="SpaceMono-Bold, Space Mono" font-weight="700" letter-spacing="-0.01em"><tspan x="-7.284" y="0">11</tspan></text>
          <g id="Group_5082" data-name="Group 5082" transform="translate(1028 471.519)">
            <path id="Path_15683-3" data-name="Path 15683" d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z" fill="url(#linear-gradient-4)"/>
            <text id="_1-3" data-name="1" transform="translate(12 16.481)" fill="#fff" font-size="12" font-family="SpaceMono-Bold, Space Mono" font-weight="700"><tspan x="-3.672" y="0">1</tspan></text>
          </g>
          <text id="_92_2" data-name="92%" transform="translate(1263 464)" fill="#fff" font-size="22" font-family="FKDisplay-Regular, FK Display, FKDisplayAlt" font-weight="600" letter-spacing="-0.03em"><tspan x="-21.01" y="0">92%</tspan></text>
          <rect id="Rectangle_2270" data-name="Rectangle 2270" width="118" height="23" rx="11.5" transform="translate(1060 472)" fill="#e1ebff"/>
          <rect id="Rectangle_2269" data-name="Rectangle 2269" width="118" height="23" rx="11.5" transform="translate(1060 434)" fill="#e1ebff"/>
          <g id="Group_5482" data-name="Group 5482" transform="translate(352 0)">
            <g id="Group_4995-3" data-name="Group 4995" transform="translate(844 390)">
              <g id="Group_5002-3" data-name="Group 5002">
                <g id="Group_5001-3" data-name="Group 5001" clip-path="url(#clip-path-4)">
                  <path id="Path_15681-3" data-name="Path 15681" d="M85,.5Z" transform="translate(-18.002 -0.106)" fill="url(#linear-gradient-5)"/>
                  <path id="Path_15682-3" data-name="Path 15682" d="M75.469,40A35.47,35.47,0,1,1,40,75.471,35.469,35.469,0,0,1,75.469,40" transform="translate(-8.471 -8.472)" fill="#fff"/>
                </g>
              </g>
              <circle id="Ellipse_123-3" data-name="Ellipse 123" cx="67" cy="67" r="67" transform="translate(0 0)" fill="url(#radial-gradient-2)"/>
            </g>
            <path id="Subtraction_6-2" data-name="Subtraction 6" d="M4.947,66.49v0h0A11.788,11.788,0,0,0,.108,65.455H0V11.13A67.1,67.1,0,0,1,17.617,13.5L4.947,66.488Z" transform="translate(911.404 378.863)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" fill="url(#linear-gradient-5)"/>
            <circle id="Ellipse_154-2" data-name="Ellipse 154" cx="36" cy="36" r="36" transform="translate(875 421)" fill="url(#linear-gradient-6)"/>
            <text id="_92_3" data-name="92%" transform="translate(911 464)" fill="#fff" font-size="22" font-family="FKDisplay-Regular, FK Display, FKDisplayAlt" font-weight="600" letter-spacing="-0.03em"><tspan x="-21.01" y="0">92%</tspan></text>
          </g>
        </g>
      </g>
    </g>
    <g id="ICONS" transform="translate(299.266 190.861)">
      <g id="Group_4985" data-name="Group 4985">
        <g id="Group_5110" data-name="Group 5110" transform="translate(-113.504 -20.222)">
          <path id="Rectangle_2238" data-name="Rectangle 2238" d="M8.244,20h0c4.558,0,8.244,3.111,8.244,6.958V153.2c0,3.847-3.686,6.958-8.244,6.958h0C3.686,160.154,0,157.043,0,153.2V26.958C0,23.111,3.686,20,8.244,20Z" transform="translate(230 3.555)" fill="url(#linear-gradient-17)"/>
          <path id="Rectangle_2250" data-name="Rectangle 2250" d="M524.244,44h0a8.238,8.238,0,0,1,8.244,8.244v121.31a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,516,173.554V52.244A8.238,8.238,0,0,1,524.244,44Z" transform="translate(184.816 -18.089)" fill="url(#linear-gradient-18)"/>
          <path id="Rectangle_2243" data-name="Rectangle 2243" d="M137.244,20h0c4.558,0,8.244,3.111,8.244,6.958V153.2c0,3.847-3.686,6.958-8.244,6.958h0c-4.558,0-8.244-3.111-8.244-6.958V26.958C129,23.111,132.686,20,137.244,20Z" transform="translate(218.704 3.555)" fill="url(#linear-gradient-17)"/>
          <path id="Rectangle_2253" data-name="Rectangle 2253" d="M653.244,44h0a8.238,8.238,0,0,1,8.244,8.244v121.31a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,645,173.554V52.244A8.238,8.238,0,0,1,653.244,44Z" transform="translate(173.52 -18.089)" fill="url(#linear-gradient-18)"/>
          <path id="Rectangle_2247-2" data-name="Rectangle 2247" d="M395.244,0h0c4.558,0,8.244,3.183,8.244,7.118V156.592c0,3.935-3.686,7.118-8.244,7.118h0c-4.558,0-8.244-3.183-8.244-7.118V7.118C387,3.183,390.686,0,395.244,0Z" transform="translate(196.112)" fill="url(#linear-gradient-21)"/>
          <path id="Rectangle_2239" data-name="Rectangle 2239" d="M51.244,57h0a8.238,8.238,0,0,1,8.244,8.244v106a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,43,171.243v-106A8.238,8.238,0,0,1,51.244,57Z" transform="translate(226.235 -15.778)" fill="url(#linear-gradient-22)"/>
          <path id="Rectangle_2257" data-name="Rectangle 2257" d="M825.244,10h0c4.558,0,8.244,3.149,8.244,7.043V154.889c0,3.894-3.686,7.043-8.244,7.043h0c-4.558,0-8.244-3.149-8.244-7.043V17.043C817,13.149,820.686,10,825.244,10Z" transform="translate(158.46 1.778)" fill="url(#linear-gradient-23)"/>
          <path id="Rectangle_2260" data-name="Rectangle 2260" d="M954.244,10h0c4.558,0,8.244,3.149,8.244,7.043V154.889c0,3.894-3.686,7.043-8.244,7.043h0c-4.558,0-8.244-3.149-8.244-7.043V17.043C946,13.149,949.686,10,954.244,10Z" transform="translate(147.163 1.778)" fill="url(#linear-gradient-23)"/>
          <path id="Rectangle_2258" data-name="Rectangle 2258" d="M868.244,10h0c4.558,0,8.244,3.149,8.244,7.043V154.889c0,3.894-3.686,7.043-8.244,7.043h0c-4.558,0-8.244-3.149-8.244-7.043V17.043C860,13.149,863.686,10,868.244,10Z" transform="translate(154.694 1.778)" fill="url(#linear-gradient-23)"/>
          <path id="Rectangle_2251-2" data-name="Rectangle 2251" d="M567.244,57h0a8.238,8.238,0,0,1,8.244,8.244v106a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,559,171.243v-106A8.238,8.238,0,0,1,567.244,57Z" transform="translate(181.051 -15.778)" fill="url(#linear-gradient-22)"/>
          <path id="Rectangle_2242" data-name="Rectangle 2242" d="M180.244,57h0a8.238,8.238,0,0,1,8.244,8.244v106a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,172,171.243v-106A8.238,8.238,0,0,1,180.244,57Z" transform="translate(214.938 -15.778)" fill="url(#linear-gradient-22)"/>
          <path id="Rectangle_2254" data-name="Rectangle 2254" d="M696.244,57h0a8.238,8.238,0,0,1,8.244,8.244v106a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,688,171.243v-106A8.238,8.238,0,0,1,696.244,57Z" transform="translate(169.755 -15.778)" fill="url(#linear-gradient-22)"/>
          <path id="Rectangle_2261" data-name="Rectangle 2261" d="M997.244,57h0a8.238,8.238,0,0,1,8.244,8.244v106a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,989,171.243v-106A8.238,8.238,0,0,1,997.244,57Z" transform="translate(143.398 -15.778)" fill="url(#linear-gradient-22)"/>
          <path id="Rectangle_2246" data-name="Rectangle 2246" d="M352.244,57h0a8.238,8.238,0,0,1,8.244,8.244v106a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,344,171.243v-106A8.238,8.238,0,0,1,352.244,57Z" transform="translate(199.878 -15.778)" fill="url(#linear-gradient-22)"/>
          <path id="Rectangle_2244" data-name="Rectangle 2244" d="M266.244,57h0a8.238,8.238,0,0,1,8.244,8.244v106a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,258,171.243v-106A8.238,8.238,0,0,1,266.244,57Z" transform="translate(207.409 -15.778)" fill="url(#linear-gradient-22)"/>
          <path id="Rectangle_2255" data-name="Rectangle 2255" d="M782.244,57h0a8.238,8.238,0,0,1,8.244,8.244v106a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,774,171.243v-106A8.238,8.238,0,0,1,782.244,57Z" transform="translate(162.225 -15.778)" fill="url(#linear-gradient-22)"/>
          <path id="Rectangle_2240-4" data-name="Rectangle 2240" d="M94.244,57h0a8.238,8.238,0,0,1,8.244,8.244v106a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,86,171.243v-106A8.238,8.238,0,0,1,94.244,57Z" transform="translate(222.469 -15.778)" fill="url(#linear-gradient-22)"/>
          <path id="Rectangle_2252" data-name="Rectangle 2252" d="M610.244,57h0a8.238,8.238,0,0,1,8.244,8.244v106a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,602,171.243v-106A8.238,8.238,0,0,1,610.244,57Z" transform="translate(177.286 -15.778)" fill="url(#linear-gradient-22)"/>
          <path id="Rectangle_2248-2" data-name="Rectangle 2248" d="M438.244,57h0a8.238,8.238,0,0,1,8.244,8.244v106a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,430,171.243v-106A8.238,8.238,0,0,1,438.244,57Z" transform="translate(192.347 -15.778)" fill="url(#linear-gradient-22)"/>
          <path id="Rectangle_2241" data-name="Rectangle 2241" d="M223.244,120h0a8.238,8.238,0,0,1,8.244,8.244v31.8a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,215,160.044v-31.8A8.238,8.238,0,0,1,223.244,120Z" transform="translate(211.173 -4.579)" fill="url(#linear-gradient-36)"/>
          <path id="Rectangle_2256" data-name="Rectangle 2256" d="M739.244,120h0a8.238,8.238,0,0,1,8.244,8.244v31.8a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,731,160.044v-31.8A8.238,8.238,0,0,1,739.244,120Z" transform="translate(165.989 -4.579)" fill="url(#linear-gradient-36)"/>
          <path id="Rectangle_2259" data-name="Rectangle 2259" d="M911.244,120h0a8.238,8.238,0,0,1,8.244,8.244v31.8a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,903,160.044v-31.8A8.238,8.238,0,0,1,911.244,120Z" transform="translate(150.929 -4.579)" fill="url(#linear-gradient-36)"/>
          <path id="Rectangle_2245" data-name="Rectangle 2245" d="M309.244,120h0a8.238,8.238,0,0,1,8.244,8.244v31.8a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,301,160.044v-31.8A8.238,8.238,0,0,1,309.244,120Z" transform="translate(203.643 -4.579)" fill="url(#linear-gradient-36)"/>
          <path id="Rectangle_2249" data-name="Rectangle 2249" d="M481.244,120h0a8.238,8.238,0,0,1,8.244,8.244v31.8a8.238,8.238,0,0,1-8.244,8.244h0A8.238,8.238,0,0,1,473,160.044v-31.8A8.238,8.238,0,0,1,481.244,120Z" transform="translate(188.582 -4.579)" fill="url(#linear-gradient-36)"/>
        </g>
      </g>
    </g>
    <g id="Group_5127" data-name="Group 5127" transform="translate(-14189 8238.938)">
      <g id="Group_5029-3" data-name="Group 5029" transform="translate(14454 -7670.938)">
        <g id="Group_5027-3" data-name="Group 5027" transform="translate(16 16)">
          <text id="Bounce_and_Spam_Rate-2" data-name="Bounce and Spam Rate" transform="translate(0 18)" fill="#131330" font-size="19" font-family="Inter" font-weight="600" letter-spacing="-0.015em"><tspan x="0" y="0">Bounce and Spam Rate</tspan></text>
        </g>
      </g>
    </g>
    <g id="Mask_Group_66" data-name="Mask Group 66" clip-path="url(#clip-path-6)">
      <path id="Path_15702" data-name="Path 15702" d="M13284.785-7287.379c0-16.082,21.3-43.6,59.824-43.6s52.032,43.6,110.171,43.6,72.239-79.012,114.712-79.012,54.555,91.5,124.657,91.5,71.05-71.887,96.905-71.887,17.614,40.371,53.818,40.371S13872.4-7361,13917.016-7361s38.171,73.617,116.212,73.617,72.684-79.012,124.7-79.012,49.028,79.012,111.257,79.012,43.065-43.6,71.819-43.6,18.785,37.15,50.529,37.15c10.127,0,11.774,2.429,11.774,6.451v29.367H13284.785Z" transform="translate(-13027.148 8008.012)" fill="#ff2428"/>
    </g>
  </g>
</svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
