import React, { Component } from 'react';
import classnames from 'classnames';

import Scale from './Images/Section6/Scale';
import Retention from './Images/Section6/Retention';
import Deliverability from './Images/Section6/Deliverability';
import Metrics from './Images/Section6/Metrics';
import Alerts from './Images/Section6/Alerts';
import Events from './Images/Section6/Events';

import Divider from '../Divider';

import styles from 'styles/Marketing/Root/Section6';

export default class Section6 extends Component {
  render() {
    return (
      <div className={classnames(styles.Container, 'pt-4', 'pb-0')}>
        <div className={classnames(styles.MaxWidth, 'mx-auto', 'px-2', 'px-lg-4', 'position-relative')} style={{zIndex: '1000'}}>
          <div className='row'>

            <div className='col-lg-4'>
              <p className={classnames(styles.callout, styles.ColorPrimaryPurple)}>FEATURES</p>
              <h3 className={classnames(styles.h3)}>Even more features to <br className='d-none d-lg-inline' />fuel your growth.</h3>
            </div>

            <div className='col-lg-8'>
              <div className={classnames(styles.Features, 'row', 'mt-3', 'mt-lg-5')}>

                  <div className='col-lg-4 pe-1'>
                    <div className={classnames(styles.Card4, 'text-center', 'p-3', 'mb-2')}>
                      <Scale  />
                      <div className={classnames(styles.Line, 'mt-3', 'mb-2')} />
                      <p><span>Powerful API</span> <br /> to send your email <br className='d-lg-block d-none' /> at scale</p>
                    </div>

                    <div className={classnames(styles.Card4, 'text-center', 'p-3', 'mb-2')}>
                      <Retention  />
                      <div className={classnames(styles.Line, 'mt-3', 'mb-2')} />
                      <p><span>2 years of data access</span> <br /> to uncover valuable user trends</p>
                    </div>

                  </div>

                  <div className='col-lg-4 px-1 h-100'>
                    <div className={classnames(styles.Card4, 'text-center', 'p-3', 'mb-2', 'h-100')}>
                      <Deliverability  />
                      <div className={classnames(styles.Line, 'mt-3', 'mb-2')} />
                      <p><span>Domain warmup and <br /> unlimited dedicated IPs</span> <br /> to supercharge deliverability</p>

                    </div>

                    <div className={classnames(styles.Card4, 'text-center', 'p-3', 'mb-2')}>
                      <Metrics  />
                      <div className={classnames(styles.Line, 'mt-3', 'mb-2')} />
                      <p><span>Quick search</span> <br /> gives you answers to any user event</p>
                    </div>
                  </div>

                  <div className='col-lg-4 ps-1'>
                    <div className={classnames(styles.Card4, 'text-center', 'p-3', 'mb-2')}>
                      <Alerts  />
                      <div className={classnames(styles.Line, 'mt-3', 'mb-2')} />
                      <p><span>Instant alerts</span> <br /> on sending events to avoid delivery problems</p>
                    </div>

                    <div className={classnames(styles.Card4, 'text-center', 'p-3', 'mb-2')}>
                      <Events  />
                      <div className={classnames(styles.Line, 'mt-3', 'mb-2')} />
                      <p><span>Integrations</span> <br /> to connect events to your datastore, CRM, and more</p>
                    </div>
                  </div>
              </div>
            </div>

          </div>
        </div>

        <Divider className={classnames(styles.Divider)} />
      </div>
    );
  }
}
